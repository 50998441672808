import axios from 'axios'
import config from "../env.config";
import * as Sentry from '@sentry/react'

const axiosInstance = axios.create({
  baseURL: config.REACT_APP_API_URL,
})

const dashboardAxiosInstance = axios.create({
  baseURL: config.REACT_APP_DASHBOARD_API_URL,
})

dashboardAxiosInstance.defaults.headers.post['Content-Type'] = '*'
dashboardAxiosInstance.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

axiosInstance.defaults.headers.post['Content-Type'] = '*'
axiosInstance.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const handleGet = async (url, requestParams) => {
  try {
    const resp = await axiosInstance({
      method: 'GET',
      url,
      params: requestParams,
    })

    if (resp.status === 200) {
      let data = resp.data

      return {
        status: resp.status,
        data,
        headers: resp.headers,
        url: resp.url,
      }
    }

    throw new Error(resp.statusText)
  } catch (err) {
    Sentry.captureException(err);
    return Promise.reject(err.message ? err.message : '')
  }
}


const handlePost = async (url, requestData, requestParams) => {
  try {
    Sentry.setContext("requestData",requestData)
    const resp = await axiosInstance({
      method: 'POST',
      url,
      data: requestData,
      params: requestParams
    })
    return resp.data
  } catch (err) {
    Sentry.captureException(err);
    return Promise.reject(err.message ? err.message : '')
  }
}

const handleGenericApiGet = async (url, requestParams) => {
  try {
    const resp = await axios({
      method: 'GET',
      url,
      params: requestParams,
    })

    if (resp.status === 200) {
      let data = resp.data

      return {
        status: resp.status,
        data,
        headers: resp.headers,
        url: resp.url,
      }
    }

    throw new Error(resp.statusText)
  } catch (err) {
    Sentry.captureException(err);
    return Promise.reject(err.message ? err.message : '')
  }
}

const handleDashboardGet = async (url, requestParams) => {
  try {
    const resp = await dashboardAxiosInstance({
      method: 'GET',
      url,
      params: requestParams,
    })

    if (resp.status === 200) {
      let data = resp.data

      return {
        status: resp.status,
        data,
        headers: resp.headers,
        url: resp.url,
      }
    }

    throw new Error(resp.statusText)
  } catch (err) {
    Sentry.captureException(err)
    return Promise.reject(err.message ? err.message : '')
  }
}

const handleDashboardPost = async (url, requestData) => {
  try {
    Sentry.setContext("requestData", requestData);
    const resp = await dashboardAxiosInstance({
      method: 'POST',
      url,
      data: requestData,
    })
    return resp.data
  } catch (err) {
    Sentry.captureException(err);
    return Promise.reject(err.message ? err.message : '')
  }
}

const handleMenteeDashboardPost = async (url, requestData, requestParam) => {
  try {
    Sentry.setContext("requestData", requestData);
    const resp = await dashboardAxiosInstance({
      method: 'POST',
      url,
      data: requestData,
      params: requestParam,
    })
    return resp.data
  } catch (err) {
    Sentry.captureException(err);
    return Promise.reject(err.message ? err.message : '')
  }
}

const handleGenericApiPost = async (url, requestData) => {
  try {
    const resp = await axios({
      method: 'POST',
      url,
      headers: {
        Accept: "application/json",
      },
      data: requestData,
    })

    if (resp.status === 200) {
      let data = resp.data

      return {
        status: resp.status,
        data,
        headers: resp.headers,
        url: resp.url,
      }
    }

    throw new Error(resp.statusText)
  } catch (err) {
    Sentry.captureException(err);
    return Promise.reject(err.message ? err.message : '')
  }
}

const handleResumePost = async (url, requestData, requestParams) => {
  try {
    let params = requestParams

    Sentry.setContext("requestData", requestData);
    const resp = await axiosInstance({
      method: 'POST',
      url,
      data: requestData,
      params: params
    })
    return resp.data
  } catch (err) {
    Sentry.captureException(err);
    return Promise.reject(err.message ? err.message : '')
  }
}

export {  handleGenericApiGet, handleGet, handlePost, handleGenericApiPost, handleDashboardPost, handleDashboardGet, handleResumePost, handleMenteeDashboardPost}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TrialFirestoreConfig {
  errorInSectionId: number;
  peepSectionId:number
  scrollMenteeToSectionId:number;
  currentStep: number;
  maxActiveStep: number;
  resume_opened: boolean;
  observeViewport: boolean;
  previousStep:number;
}

const initialState: TrialFirestoreConfig = {
  errorInSectionId: 0,
  peepSectionId:4,
  scrollMenteeToSectionId:4,
  currentStep: 0,
  maxActiveStep: 0,
  resume_opened: false,
  observeViewport: false,
  previousStep:0
};

export const trialFirestoreConfigReducer = createSlice({
  name: "stepsState",
  initialState,
  reducers: {
    setTrialDocConfig: (state: any, action: PayloadAction<any>) => {
      var trialDocConfig = action.payload.key;
      if((trialDocConfig === "currentStep" || trialDocConfig === "errorInSectionId") && action.payload.value !== state[trialDocConfig]){
        state.observeViewport = false
      }
      state[trialDocConfig] = action.payload.value;
    },
    setPeepSectionId: (state: any, action: PayloadAction<number>) => {
      state.peepSectionId = action.payload
    },
    setObserveViewport: (state: any, action: PayloadAction<boolean>) => {
      state.observeViewport = action.payload
    },
    setPreviousStep: (state: any, action: PayloadAction<number>) => {
      state.previousStep = action.payload
    }
  },
});

export const { setTrialDocConfig,setPeepSectionId,setObserveViewport,setPreviousStep } = trialFirestoreConfigReducer.actions;
export default trialFirestoreConfigReducer.reducer;
import React from 'react'

type Props = {
  skills: Array<String>
  className: string
}

const Skill = (props: Props) => {
  return (
    <div className={props.className}>
      <div className='flex flex-wrap gap-x-2 gap-y-4'>
        {
          props.skills.map((skillTitle,i:number) =>
            <div key = {i} className='bg-blue-50 rounded-full w-fit px-4 py-1 text-blue-700'>{skillTitle}</div>
          )
        }
      </div>
    </div>
  )
}

Skill.defaultProps = {
  className: ""
}

export default Skill
import { useState } from 'react'

interface CustomImagePropType {
  photoUrl?: string
  name: string
  className?: string
}

const CustomImage: React.FC<CustomImagePropType> = ({ photoUrl, name, className }) => {
  const [error, setError] = useState(false)
  const classNameText = 'object-cover w-10 h-10 rounded-full ' + className
  return (
    <>
      {error ? (
        <img src={`https://ui-avatars.com/api/?name=${name ? name : ''}&length=1`} alt={'Image of ' + name} className={classNameText} />
      ) : (
        <img
          src={photoUrl}
          alt={'Image of ' + name}
          className={classNameText}
          onError={() => {
            setError(true)
          }}
        />
      )}
    </>
  )
}

export default CustomImage

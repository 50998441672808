import { FC } from "react";
import Header from "../header/header";
import Loader from "common/Loader/Loader";
import usePostSyncupFeedbackMentorHook from "./use-post-syncup-feedback-mentor-hook";
import { ReactComponent as EnterIcon } from "assets/svgs/hit-enter-icon.svg";
import Tippy from "@tippyjs/react";
interface PostSyncupFeedbackMentorProps {
  handleNextScreen: Function;
  menteeDetails: any;
  mentorDetails: any;
  packageRecordId?: string;
}

const PostSyncupFeedbackMentor: FC<PostSyncupFeedbackMentorProps> = ({
  handleNextScreen,
  menteeDetails,
  mentorDetails,
  packageRecordId
}) => {
  const {
    handleChanges,
    currentTasks,
    handleKeyDown,
    tasksList,
    buttonClassesSelector,
    handleContinue,
    loader,
  } = usePostSyncupFeedbackMentorHook({
    menteeDetails,
    mentorDetails,
    handleNextScreen,
    packageRecordId
  });

  return (
    <div className="w-full h-full pb-6 bg-white rounded-xl shadow flex-col justify-start items-start gap-6 inline-flex">
      {/* Header */}
      <Header
        menteeDetails={menteeDetails}
        mentorDetails={mentorDetails}
      />
      {/* Body */}
      <div className="flex-col h-full justify-between items-end flex w-full">
        {/* Body Header */}
        <div className="px-6 h-full flex-col justify-start items-start gap-6 flex w-full">
          <div className="self-stretch flex-col justify-start items-start gap-4 flex">
            <div className="flex-col justify-start items-start gap-2 flex">
              <div className="text-slate-700 text-base font-semibold  leading-normal">
                1. Any{" "}
                <span className="text-blue-700 text-base font-semibold  leading-normal">
                  tasks{" "}
                </span>
                which are to be completed by next session
              </div>
              <div className="self-stretch px-1 justify-start items-start gap-2.5 inline-flex">
                <div className="grow shrink basis-0 text-gray-500 text-xs font-normal  leading-[18px]">
                  Track these task later on your dashboard. In the next session,
                  you can easily see how many of these are completed.
                </div>
              </div>
            </div>
            <div className="border h-[0.5px] w-full border-gray-200"></div>
          </div>
          <div className="w-full">
            <div className="items-center gap-2 inline-flex w-full my-2 ">
              <div className="w-5 h-5 rounded border border-gray-400" />
              {/* Add Task Component Here */}
              <input
                placeholder="Add a task here..."
                className="text-slate-700 pl-1 text-md p-1 border-none font-medium flex-1"
                value={currentTasks}
                onChange={handleChanges}
                onKeyDown={handleKeyDown}
              />
              {currentTasks && (
                <div className="text-gray-500 text-[10px] font-normal whitespace-nowrap">
                  Enter <EnterIcon className="inline-block mx-[0.5px]" /> to add
                  task
                </div>
              )}
            </div>

            <div
              className="flex-col h-full w-full overflow-auto justify-start items-start gap-4 flex mb-2"
              style={{ maxHeight: "200px" }}
            >
              {/* task List here */}
              {tasksList?.map((taskDetails, key) => (
                <div
                  className="justify-start items-center gap-1.5 inline-flex my-1 w-full"
                  key={key}
                >
                  <div className="w-5 h-5 rounded border border-gray-400" />
                  <div className="text-slate-700 pl-2 text-md font-medium leading-none">
                    {taskDetails}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Continue Button */}
        {tasksList?.length ? (
          <>
            <div className="self-stretch px-6 justify-start items-start gap-4 inline-flex mt-2">
              <div
                onClick={handleContinue}
                className={`grow shrink basis-0 px-6 py-3 rounded shadow border justify-center items-center gap-2 flex
            ${buttonClassesSelector()}
            `}
              >
                <div className="text-center text-sm font-semibold  leading-none">
                  {loader ? <Loader /> : "Continue"}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Tippy content="👋 Heads up! Before you can proceed, please make sure to add the tasks discussed in the meeting. Your input is crucial! Once added, you can move forward with ease. 🚀">
            <div className="self-stretch px-6 justify-start items-start gap-4 inline-flex mt-2">
              <div
                onClick={handleContinue}
                className={`grow shrink basis-0 px-6 py-3 rounded shadow border justify-center items-center gap-2 flex
            ${buttonClassesSelector()}
            `}
              >
                <div className="text-center text-sm font-semibold  leading-none">
                  {loader ? <Loader /> : "Continue"}
                </div>
              </div>
            </div>
          </Tippy>
        )}
      </div>
    </div>
  );
};

export default PostSyncupFeedbackMentor;

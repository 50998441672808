import { LoaderWithThreeDots } from 'common/Loader/LoaderWthThreeDots'
import React from 'react'
import useUploadResumeHook from './useUploadResumeHook'
import { useAppSelector } from 'state/hooks'
import PDFModal from 'common/PdfModal/PdfModal'
import CheckIconCustomStroke from "assets/icons/check-icon-custom-stroke.svg";

const UploadMenteeResume = () => {
  const { handleResumeClose, menteeData, handleResumeOpen, resumeOpened, resumeUrl, fileName, fileLoading, handleResumeSubmit, handleResumeInput, errorResponse } = useUploadResumeHook()

  if (resumeUrl) {
    return (
      <div className=''>
        <button disabled={!resumeUrl} className={`mt-5 flex gap-x-2 items-center px-3 py-2.5 bg-[#222] rounded-lg border border-[#fff]/[0.05] ${resumeUrl ? "text-white" : "text-[#fff]/[0.3] cursor-not-allowed"}`} onClick={handleResumeOpen}>
          <img src={CheckIconCustomStroke} alt="GreenCheck.svg" />
          <p className="font-medium text-[13px] leading-[18px]">View resume</p>
        </button>
        <PDFModal
          pdfUrl={resumeUrl}
          open={resumeOpened}
          modalTitle={`${menteeData?.candidateName}'s Resume`}
          handleCloseOutside={handleResumeClose}
          handleClose={handleResumeClose}
          className="w-[calc(100vw-30rem)] h-[calc(100vh-10rem)]"
        />
      </div>
    )
  }
  return (
    <div className='flex w-full justify-between gap-x-1 mt-5'>
      {fileName ? (
        <button
          disabled={fileLoading}
          onClick={handleResumeSubmit}
          className='relative flex items-center gap-x-2 w-fit py-2 px-4 bg-white text-black rounded-md cursor-pointer'
        >
          {fileLoading ? (
            <LoaderWithThreeDots color="#000" />
          ) : (
            <div className='flex gap-y-1'>
              <p className='flex text-sm font-medium items-center gap-x-1'>Save -&gt;</p>
              <p className='absolute left-0 text-white/[0.7] underline text-xs bottom-0 translate-y-[120%]'>{fileName}</p>
            </div>
          )}
        </button>
      ) : (
        <button className="relative cursor-pointer">
          <div className="cursor-pointer py-2 px-4 bg-white text-black rounded-lg">
            <span className='text-[13px] leading-5 font-medium'>Upload Resume</span>
          </div>
          <input
            type="file"
            onChange={handleResumeInput}
            className={`absolute w-full h-full opacity-0 right-0 top-0 cursor-pointer`}
            title=""
            accept="application/pdf"
          />
        </button>
      )}
      {errorResponse && (
        <i className="text-red-500 text-sm">{errorResponse}</i>
      )}
    </div>
  )
}

export default UploadMenteeResume
import React, { useState } from 'react'

type Props = {
  targetDomains: any
  minCount: number
}

const MenteeTargetDomains = ({
  targetDomains,
  minCount
}: Props) => {
  const [showSkills, setShowSkills] = useState(minCount);
  return (
    <div className='flex flex-wrap gap-3'>
      {targetDomains
        .slice(0, showSkills)
        .map((item: any, index: number) => (
          <p
            key={index}
            className="text-white text-xs font-medium py-2 px-3 rounded-full bg-[#222]"
          >
            {item}
          </p>
        ))}
      {targetDomains.length > minCount &&
        (showSkills <= minCount ? (
          <p
            className="text-white text-xs py-2 cursor-pointer font-semibold"
            onClick={() => {
              setShowSkills(targetDomains.length);
            }}
          >
            +{targetDomains.length - minCount} More
          </p>
        ) : (
          <p
            className="text-white text-xs py-2 cursor-pointer font-semibold"
            onClick={() => {
              setShowSkills(minCount);
            }}
          >
            Show Less
          </p>
        ))}
    </div>
  )
}

export default MenteeTargetDomains
import React, { useState } from 'react'
import GPS from 'assets/icons/gps-white.svg'
import Flag from 'assets/icons/FlagWhite.svg'
import { useAppSelector } from 'state/hooks'
import { Link } from 'react-scroll'
import { sendAnalyticsToSegment } from 'api/analyticsV2'
import { newEventPropertyBuilder } from 'api/planning'
import { trackAnalytics } from 'api/analytics'

type Props = {
  interviewReadiness: number
  className?: string
}

const Timeline = ({ interviewReadiness, className }: Props) => {
  const menteeData = useAppSelector(state => state.planner.menteeData)
  const mentorData = useAppSelector(state => state.planner.mentorData)
  const initialUpgradeLaunchTime = useAppSelector(state => state.generalConfigs.trial_upgrade_opened_time)
  const menteeEmail = useAppSelector(state => state.generalConfigs.menteeEmail)
  const mentorEmail = useAppSelector(state => state.generalConfigs.mentorEmail)
  return (
    <div className={className}>
      <div className='flex justify-center'>
        <div className='w-[19rem] sm:w-[25rem] md:w-[47rem] lg:w-[60rem] xl:w-[70rem]'>
          <h3 className='font-bold text-2xl py-3 md:py-5 px-2'>Your Timeline</h3>
          {/* <div className='flex'><button onClick={() => setinterviewReadiness(interviewReadiness + 1)} className="m-4">+</button>
          <p>{interviewReadiness}</p>
          <button onClick={() => setinterviewReadiness(interviewReadiness - 1)}>-</button></div> */}
          <div className='pl-2 sm:pl-24 md:pl-28'>
            <div className='pl-3 pb-2'>
              <p className='text-sm text-[#667085]'>Background</p>
              {menteeData.userCategory === "Working Professional" && <p className='font-medium'>{menteeData?.currentRole} at {menteeData?.currentCompany}</p>}
              {menteeData.userCategory === "Fresher looking for their first job" && <p className='font-medium'>Pursuing {menteeData?.collegeDegree} at {menteeData?.collegeName}</p>}
              {menteeData.userCategory === "Unemployed but worked before" && <p className='font-medium'>Previously Worked at {menteeData?.pastCompany} as {menteeData?.pastRole}</p>}
            </div>
            <div className='flex flex-col w-fit items-center'>
              <img src={GPS} alt="" className='w-7 h-7 rounded-full bg-[#1d2939] p-2' />
              {[...Array(Math.floor(interviewReadiness))].map((score, i: number) => <div key={i} className={`h-[1.5rem] md:h-[2rem] w-0 border-[1px] border-[#1d2939]`} />)}
              <div className='flex relative items-center'>
                <img className={`w-10 h-10 rounded-full border-2 border-[#1d2939]`} src={menteeData?.photoUrl} alt="" onError={(event: any) => {
                  event.target.src = `https://ui-avatars.com/api/?name=${menteeData?.candidateName}&background=7f56d9&color=fff&length=2&bold=true`
                  event.onerror = null
                }} />
                <div className='hidden sm:block absolute whitespace-nowrap right-12 md:right-14 md:text-base text-sm font-bold'>YOU ARE HERE</div>
                <div className={`p-4 flex items-center justify-evenly w-56 md:w-96 bg-white shadow-xl absolute ${interviewReadiness < 5 ? "left-16" : "left-8"} ${interviewReadiness < 5 ? "-bottom-4" : "bottom-16"} 
            ${interviewReadiness < 5 ? "before:border-y-[10px] before:border-y-transparent before:border-r-[10px] before:border-r-white before:block before:top-[60px] before:-left-[10px] before:absolute"
                    : "before:border-r-[20px] before:border-r-transparent before:border-t-[20px] before:border-t-white before:block before:-bottom-[20px] before:left-0 before:absolute"}`}>
                  <div className='flex flex-col justify-center'>
                    <p className='text-[#667085] text-xs font-medium hidden md:block'>Mentor's evaluation</p>
                    <h3 className='font-semibold md:text-base text-sm text-[#667085] md:text-black'>Your interview readiness score is</h3>
                    <Link to='report_analysis' offset={-50} duration={300} smooth className='text-xs bg-[#101828] text-white rounded-lg md:mr-10 mr-2 mt-1 p-1 text-center cursor-pointer'
                      onClick={() => {
                        const viewReportAnalysisClickedEventBuilder = newEventPropertyBuilder({
                          mentee_email: menteeEmail,
                          mentor_email: mentorEmail,
                          user_email: menteeData?.email,
                          product_name: "Upgrade Report",
                          event_name: "View Report Analysis Clicked",
                          extra_track_properties: {
                            time_passed: Math.round(
                              (new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000)
                            ),
                          },
                        });
                        trackAnalytics(viewReportAnalysisClickedEventBuilder)
                        // sendAnalyticsToSegment.track("View Report Analysis Clicked", {
                        //   time_passed: Math.round((new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000))
                        // })
                      }}>
                      View Report Analysis
                    </Link>
                  </div>
                  <div className='bg-[#fef3f2] text-[#f04438] font-bold md:text-xl p-2 md:p-4 rounded-lg'>{interviewReadiness}/10</div>
                </div>
              </div>
              <div className={`flex flex-col justify-center relative`}>
                <div>{[...Array(Math.floor(10 - (interviewReadiness)))].map((score, i: number) => <div key={i} className={`h-[1.5rem] md:h-[2rem] w-0 border-[1px] border-[#f04438]`} />)}</div>
                <div className='flex h-20 md:h-24 absolute'>
                  <div className='md:w-32 w-10 h-0 border-dashed border-[1px] border-[#f04438] self-center'></div>
                  <div className='md:w-fit w-52 md:pr-4 md:pl-2 bg-white shadow-xl rounded-lg relative flex items-center'>
                    <img className={`w-10 h-10 rounded-full border-2 border-[#1d2939] absolute -left-5`} src={mentorData?.photoUrl} alt="" onError={(event: any) => {
                      event.target.src = `https://ui-avatars.com/api/?name=${mentorData?.name}&background=7f56d9&color=fff&length=2&bold=true`
                      event.onerror = null
                    }} />
                    <div className='pl-7'>
                      <p className='text-[#667085] font-medium text-xs hidden md:block'>Mentor's Plan</p>
                      <p className='font-semibold md:whitespace-nowrap hidden md:block'>Here is a plan of action to achieve your goal 🚀</p>
                      <p className='font-semibold md:whitespace-nowrap text-sm text-[#667085] pb-2 md:hidden'>Your mentor’s plan is here</p>
                      <Link to="plan_of_action" offset={-50} duration={300} smooth className='text-center cursor-pointer text-xs bg-[#101828] text-white rounded-lg mt-1 py-1 px-5'
                        onClick={() => {
                          const viewPlanClickedEventBuilder = newEventPropertyBuilder({
                            mentee_email: menteeEmail,
                            mentor_email: mentorEmail,
                            user_email: menteeData?.email,
                            product_name: "Upgrade Report",
                            event_name: "View Plan Clicked",
                            extra_track_properties: {
                              time_passed: Math.round(
                                (new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000)
                              ),
                            },
                          });
                          trackAnalytics(viewPlanClickedEventBuilder)
                          // sendAnalyticsToSegment.track("View Plan Clicked", {
                          //   time_passed: Math.round((new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000))
                          // })
                        }}>View Plan</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-7 h-7 rounded-full bg-[#1d2939] p-2 flex items-center justify-center'>
                <img src={Flag} alt="" className='' />
              </div>
            </div>
            <div className='pt-2'>
              <p className='text-sm text-[#667085]'>Goal</p>
              {menteeData?.targetCompanies[0] === "Not Targeting anything specific" ? <p className='font-medium'>{menteeData?.targetRoles}</p> :
                <p className='font-medium'>{menteeData?.targetRoles} at {menteeData?.targetCompanies[0]}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Timeline
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { updateFirestore } from '../../api/planning';

export interface StepsState {
    step_one_section_one: boolean;
    step_one_section_two: boolean;
    step_one_section_three: boolean;
    step_two_section_one: boolean;
    step_two_section_two: boolean;
    step_three_section_one: boolean;
    step_three_section_two: boolean;
    step_three_section_three: boolean;
}

const initialState: StepsState = {
    step_one_section_one: false,
    step_one_section_two: false,
    step_one_section_three: false,
    step_two_section_one: false,
    step_two_section_two: false,
    step_three_section_one: false,
    step_three_section_two: false,
    step_three_section_three: false
};

export const sectionExpansionReducer = createSlice({
    name: "sectionExpansion",
    initialState,
    reducers: {
        setExpandedSection: (state: any, action: PayloadAction<any>) => {
            var key = action.payload.key;
            state[key] = action.payload.value
        },
    }
})

export const { setExpandedSection } = sectionExpansionReducer.actions;
export default sectionExpansionReducer.reducer;
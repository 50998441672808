import React, { useState } from 'react'
import { useAppSelector } from 'state/hooks'
import RocketLaunch from 'assets/icons/RocketLaunch2.svg'
import Target from 'assets/icons/Target2.svg'
import NavigationArrow from 'assets/icons/NavigationArrow.svg'
import Check from 'assets/icons/Check.svg'
import Star from 'assets/icons/RatingStarFilled.svg'
import Briefcase from 'assets/icons/BriefcaseIcon.svg'
import MentorWorkedAtCard from 'routes/trial-session/step1/Components/MentorWorkedAtCard'

type Props = {}

const PlanOfActionMentorCard = (props: Props) => {
  const mentorData = useAppSelector(state => state.planner.mentorData);
  const [imageAvailable, setImageAvailable] = useState(false);
  const [clearBit, setClearBit] = useState(false);
  const [clearSplitBit, setClearSplitBit] = useState(false);
  const [clearSplitBitTwo, setClearSplitBitTwo] = useState(false);
  const [color, setColor] = useState("");
  const companyLogoUrl = mentorData?.careerJourneyJson[0].logo_url;
  return (
    <div className='flex md:flex-row flex-col items-center justify-between gap-8 py-12 md:px-8 lg:px-16 xl:px-28'>
      <div className='flex flex-col items-center w-fit'>
        <img className={`rounded-full w-48 h-48 shadow-md`} src={mentorData?.photoUrl} alt="" onError={(event: any) => {
          event.target.src = `https://ui-avatars.com/api/?name=${mentorData?.name}&background=7f56d9&color=fff&length=2&bold=true`
          event.onerror = null
        }} />
        <h3 className='font-bold text-2xl pt-2 w-fit'>{mentorData?.name}</h3>
        <div className='flex gap-1 items-center w-fit'>
          <p className='font-medium whitespace-nowrap'>
            {mentorData?.currentDesignation}
            <span className='font-normal'> at </span>
            {mentorData?.currentCompany}
          </p>
          <div className="">
            {imageAvailable ? (
              <img
                className="w-[18px] h-[18px] rounded-full object-contain"
                src={
                  clearBit
                    ? "https://logo.clearbit.com/" +
                    mentorData?.currentCompany.trim().toLocaleLowerCase() +
                    ".com"
                    : clearSplitBit
                      ? `https://logo.clearbit.com/${mentorData?.currentCompany
                        .split(" " || "-")[0]
                        .trim()
                        .toLocaleLowerCase()}.com`
                      : clearSplitBitTwo
                        ? `https://logo.clearbit.com/${mentorData?.currentCompany
                          .split("-")[0]
                          .trim()
                          .toLocaleLowerCase()}.com`
                        : companyLogoUrl
                }
                width="42"
                height="42"
                alt={"company_logo"}
                onError={() => {
                  setImageAvailable(false);
                  setClearBit(false);
                  setClearSplitBit(false);
                }}
              />
            ) : (
              <div
                className={`flex justify-center items-center rounded-full w-[18px] h-[18px] ${color}`}
              >
                <p className="font-medium text-sm bg-blue-800 text-white w-[18px] h-[18px] text-center rounded-full">
                  {mentorData?.currentCompany.trim().substring(0, 1)}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className='flex gap-2 text-sm pt-2'>
          <p>
            Also worked at
          </p>
          <div className="flex gap-x-1 items-center">
            {mentorData?.careerJourneyJson
              .slice(1, 4)
              .map((item: any, index: number) => (
                <MentorWorkedAtCard
                  key={index}
                  companyLogoUrl={item.logo_url}
                  companyName={item.company}
                />
              ))}
          </div>
          <div className='bg-white rounded-full px-4 font-medium py-[2px] flex gap-2'>
            <img src={Briefcase} alt="" />
            {mentorData?.totalExperience !== 0 && 
              <p>
                {mentorData?.totalExperience} Yrs Experience
              </p>
            } 
            </div>
        </div>
      </div>
      <div className='bg-[#fffaeb] shadow-md md:rounded-xl py-2 px-8'>
        {(mentorData.totalSessions > 0 || mentorData.averageSessionRating > 0) && <><div className='w-full flex gap-4 justify-between md:justify-end items-center py-2'>
          {mentorData.totalSessions > 0 && <div className='flex gap-1 text-sm items-center'>
            <p className='font-bold'>{mentorData.totalSessions}</p>
            <p className='text-[#667085]'>Sessions</p>
          </div>}
          <p className='text-[#667085] hidden md:block'>|</p>
          {mentorData.averageSessionRating > 0 && <div className='flex gap-1 text-sm'>
            <p className='font-bold'>{mentorData.averageSessionRating.toFixed(2)}</p>
            <img src={Star} alt="" />
            <p className='text-[#667085]'>Rating</p>
          </div>}
        </div>
          <hr className='mb-4' /></>}
        <h4 className='font-bold mr-8'>Here is how the mentor would be helping you become interview ready</h4>
        <div className='flex flex-col gap-2 my-4 md:text-base text-sm'>
          <div className='flex items-center gap-2'>
            <img src={NavigationArrow} alt="" />
            <p>Providing expert guidance and direction you need</p>
          </div>
          <div className='flex items-center gap-2'>
            <img src={Target} alt="" />
            <p>Motivating and helping you to achieve your goal</p>
          </div>
          <div className='flex items-center gap-2'>
            <img src={Check} alt="" />
            <p>Give you the tips to make you stand out from others</p>
          </div>
          <div className='flex items-center gap-2'>
            <img src={RocketLaunch} alt="" />
            <p>Ensuring that your interview preparation is on track</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlanOfActionMentorCard
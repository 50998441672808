import { sendAnalyticsToSegment } from "api/analyticsV2";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useCreateTaskMutation } from "services/queries/task/task";
import { QueryParams } from "../use-main-hook";
import { postMessageTalkJs } from "api/mentor-talkjs";
import { usePostAnalyticsMutation } from "services/queries/analytics/analytics";

type usePostSyncupFeedbackMentorHookType = {
  menteeDetails: any;
  mentorDetails: any;
  handleNextScreen: Function;
  packageRecordId?: string;
};

const usePostSyncupFeedbackMentorHook = ({
  menteeDetails,
  mentorDetails,
  handleNextScreen,
  packageRecordId
}: usePostSyncupFeedbackMentorHookType) => {
  const [currentTasks, setCurrentTasks] = useState("");
  const [tasksList, setTasksList] = useState<string[]>([]);
  let { id: sessionRecordId } = useParams();
  let [searchParams] = useSearchParams();
  const [loader, setLoader] = useState(false);
  const [ sendAnalyticsThroughtBackEnd ] = usePostAnalyticsMutation();

  /* -------------------------------------------------------------------------- */
  /*                                  RTK Query                                 */
  /* -------------------------------------------------------------------------- */
  const [postMenteeTasks] = useCreateTaskMutation();
  /* -------------------------------------------------------------------------- */
  /*                                  Handlers                                  */
  /* -------------------------------------------------------------------------- */
  const handleContinue = async () => {
    if(!tasksList?.length) return;
    setLoader(true);
    const message =
      "Added the tasks from our discussion in the session. You can check it out in the my-tasks section. Let's try to complete this before our next session.";

    const postCallData = {
      mentee_id: searchParams.get(QueryParams.MENTEE_RECORD_ID),
      mentor_id: searchParams.get(QueryParams.MENTOR_RECORD_ID),
      message: message,
      message_from: "Mentor",
    };

    let response;
    if (tasksList?.length) {
      response = await postMessageTalkJs(postCallData);
    }
    handleNextScreen();
    sendAnalyticsToSegment.track("Button Clicked", {
      button_name: tasksList?.length ? "Continue" : "Continue without any task",
      product: "planner",
    });
    setLoader(false);
    return response;
  };
  
  const handleChanges = (e: any) => {
    setCurrentTasks(e.target.value);
  };

  const analyticsTasksCreated = (taskTitle: string) => {
    try {
      const analyticsData = {
        eventName: "Task Created",
        properties: {
          task: {
            name: taskTitle,
          },
          mentor: {
            name: mentorDetails?.name,
            email: mentorDetails?.email,
            id: mentorDetails?.recordId,
          },
          mentee: {
            name: menteeDetails?.name,
            email: menteeDetails?.email,
            id: menteeDetails?.recordId,
          },
          product: "planner",
          session_id: sessionRecordId
        },
      };
      const requestBody = {
        user_id: menteeDetails?.email,
        event: analyticsData?.eventName,
        properties: {
          url: (window as any).location.href || null,
          referrer: document.referrer,
          ...analyticsData?.properties
        },
      }

      sendAnalyticsToSegment.track(
        analyticsData.eventName,
        analyticsData.properties
      );

      sendAnalyticsThroughtBackEnd(requestBody)
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async () => {
    let taskTitle = currentTasks;

    setCurrentTasks("");

    let requestBody: any = {
      params: {
        user: "Mentor",
        user_record_id: searchParams.get(QueryParams.MENTOR_RECORD_ID),
      },
      body: {
        title: taskTitle,
        candidate: [searchParams.get(QueryParams.MENTEE_RECORD_ID)],
        ...(sessionRecordId && { session: [sessionRecordId!] }),
        ...(packageRecordId && { packages: [packageRecordId]})
      },
    };

    setTasksList((pre) => [taskTitle, ...pre]);
    const response = await postMenteeTasks(requestBody).unwrap();
    analyticsTasksCreated(taskTitle);
    return response;
  };

  const handleKeyDown = (evt: any) => {
    if (evt.key === "Enter") {
      handleSubmit();
    }
  };

  const buttonClassesSelector = () => {
    if(tasksList?.length){
      return "bg-zinc-950 border-zinc-950 text-white cursor-pointer"
    }else{
      return "bg-gray-600 border-gray-600 text-white cursor-not-allowed"
    }
  }
  

  return { handleChanges, currentTasks, handleKeyDown, tasksList, buttonClassesSelector, handleContinue, loader };
};

export default usePostSyncupFeedbackMentorHook;

import React, { useState } from 'react'

type Props = {
  targetCompanies: any
  minCount: number
}

const MenteeTargetCompanies = ({
  targetCompanies,
  minCount
}: Props) => {
  const [showCompanies, setShowCompanies] = useState(minCount);
  return (
    <div className='flex flex-wrap gap-3'>
      {targetCompanies
        .slice(0, showCompanies)
        .map((item: any, index: number) => (
          <p
            key={index}
            className="text-white text-xs font-medium py-2 px-3 rounded-full bg-[#222] space-x-1.5"
          >
            <span>{item === 'MAANG' ? '💪' : item === 'Startups' ? '🦄' : item === 'Product Based' ? '📦' : '✊'}</span>
            <span>
              {item}
            </span>
          </p>
        ))}
      {targetCompanies.length > minCount &&
        (showCompanies <= minCount ? (
          <p
            className="text-white text-xs py-2 cursor-pointer font-semibold"
            onClick={() => {
              setShowCompanies(targetCompanies.length);
            }}
          >
            +{targetCompanies.length - minCount} More
          </p>
        ) : (
          <p
            className="text-white text-xs py-2 cursor-pointer font-semibold"
            onClick={() => {
              setShowCompanies(minCount);
            }}
          >
            Show Less
          </p>
        ))}
    </div>
  )
}

export default MenteeTargetCompanies
import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../store";

interface initialStateProps {
  count: number;
  join_role: string;
  menteeEmail:string;
  mentorEmail:string;
  trial_doc_opened_time: number;
  trial_upgrade_opened_time: number;
  country: any;
  navbarAreaHeight: number;
  isAuthorizedMentor: boolean;
  hasMentorOptedIPP:boolean
  totalIppSessionsCount:number
  totalIsSessionsCount:number
}

const initialState: initialStateProps = {
  count: 0,
  join_role: "mentor",
  menteeEmail: "",
  mentorEmail: "",
  trial_doc_opened_time: new Date().getTime(),
  trial_upgrade_opened_time: new Date().getTime(),
  country: "IN",
  navbarAreaHeight: 0,
  isAuthorizedMentor: true,
  hasMentorOptedIPP:false,
  totalIppSessionsCount:0,
  totalIsSessionsCount:0
};

const generalConfigsSlice = createSlice({
  name: "GeneralAppConfigs",

  initialState: {
    ...initialState,
  },

  reducers: {
    setLocationInfo: (state: any, action: PayloadAction<number>) => {
      state.country = action.payload;
    },
    setCount: (state: any, action: PayloadAction<number>) => {
      state.generalConfigs.count = action.payload;
    },
    setRole: (state: any, action: PayloadAction<string>) => {
      state.join_role = action.payload;
    },
    setNavbarAreaHeight: (state: any, action: PayloadAction<string>) => {
      state.navbarAreaHeight = action.payload;
    },
    setIsAuthorizedMentor: (state: any, action: PayloadAction<boolean>) => {
      state.isAuthorizedMentor = action.payload;
    },
    setHasMentorOptedIPP: (state: any, action: PayloadAction<boolean>) => {
      state.hasMentorOptedIPP = action.payload;
    },
    setTotalIppSessionsCount: (state: any, action: PayloadAction<number>) => {
      state.totalIppSessionsCount = action.payload;
    },
    setTotalIsSessionsCount: (state: any, action: PayloadAction<number>) => {
      state.totalIsSessionsCount = action.payload;
    },
    setMenteeEmail: (state: any, action: PayloadAction<string>) => {
      state.menteeEmail = action.payload;
    },
    setMentorEmail: (state: any, action: PayloadAction<string>) => {
      state.mentorEmail = action.payload;
    }
  },
});

export const {
  setCount,
  setRole,
  setLocationInfo,
  setNavbarAreaHeight,
  setIsAuthorizedMentor,
  setHasMentorOptedIPP,
  setTotalIppSessionsCount,
  setTotalIsSessionsCount,
  setMenteeEmail,
  setMentorEmail
} = generalConfigsSlice.actions;

export default generalConfigsSlice.reducer;
export const selectGeneralConfigs = (state: RootState) => state.generalConfigs;

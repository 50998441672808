import { configureStore } from "@reduxjs/toolkit";

// reducers
import generalConfigsReducer from "./common/generalConfigsReducer";
import trialFirestoreConfigReducer from "./stepsReducer/stepsReducer";
import plannerInputAreasReducer from "./planner-input-areas-reducer/plannerInputAreasReducer";
import plannerDoc2Reducer from "./planner/plannerDoc2Reducer";
import configurationsReducer from "./planner/configurationsReducer";
import activeSectionReducer from "./activeSection/activeSectionReducer";
import sectionExpansionReducer from "./section-expansion/sectionExpansionReducer";
import errorInputReducer from "./errorInputReducer/errorInputReducer";
import plannerReducer from "./planner/plannerReducer";
import accordionReducer from "./accordion/accordionReducer";
import timelineReducer from "./timeline/timelineReducer";
import { baseApi } from "services/base-api";

// configuring store - ( In new version of redux - it's automatically combined reducers & add middleware for thunk )
const store = configureStore({
  reducer: {
    stepsState: trialFirestoreConfigReducer,
    plannerInputAreas: plannerInputAreasReducer,
    generalConfigs: generalConfigsReducer,
    planner: plannerReducer,
    plannerDoc2: plannerDoc2Reducer,
    configurations: configurationsReducer,
    activeSection: activeSectionReducer,
    sectionExpansion: sectionExpansionReducer,
    errorInput: errorInputReducer,
    accordion: accordionReducer,
    timelineState: timelineReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      baseApi.middleware
    ),
});

// to use typescript intellisense in components ( check file inside - state/hooks.ts & use of it )
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import { handlePost } from "./axios";
import config from "../env.config";

/* -------------------------------------------------------------------------- */
/*                        Track Mentor Mentee Analytics                       */
/* -------------------------------------------------------------------------- */

// Common Analytics
export const trackAnalytics = async (reqBody: any) => {
  const queryString = window.location.search;
  const queryPathName = window.location.pathname;
  const urlParams = new URLSearchParams(queryString);
  const isRecorder = urlParams.get("recorder")
  const role = urlParams.get("role")
  if(queryPathName.split('/')[1] === "upgrade" || (isRecorder === "false" && role !== "preplaced")){
    try {
        //testing
        const eventBody = {
          user_id: reqBody.user_id,
          event: reqBody.event,
          properties: reqBody.properties,
        }
        const response = await handlePost(config.REACT_APP_TRACK_ANALYTICS,eventBody);
        console.log(eventBody)
        return response;
    } catch (error) {
      console.error("Error in trackAnalytics",error);
    }
  }
};

export const identifyAnalytics =async (reqBody:any) => {
  try {
      const response = await handlePost(
        config.REACT_APP_IDENTIFY_ANALYTICS,
          {
            user_id: reqBody.user_id,
            traits: reqBody.traits,
          }
      );
      return response;
  } catch (error) {
    console.error("Error in identifyAnalytics",error);
  }
}

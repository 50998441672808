import config from "env.config"

type joinUrlType = {
  endpoint: string
  isBaseCommon?: boolean
}

export const joinUrl = ({ endpoint, isBaseCommon }: joinUrlType): string => {
  let baseUrl = config.REACT_APP_DASHBOARD_API_URL //by Default the Base is Dashboard API's

  if (isBaseCommon) {
    baseUrl = config.REACT_APP_API_URL
  }

  return baseUrl + endpoint
}

import { baseApi } from "services/base-api";
import { endPoints } from "services/end-points";

/* -------------------------------------------------------------------------- */
/*                                 API Calling                                */
/* -------------------------------------------------------------------------- */
export interface Task {
  title: string;
  mentorNotes?: string;
  candidate?: string[];
}

export const myMenteeSessionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    
    // Create Tasks
    createTask: builder.mutation<Task, Partial<Task>>({
      query: ({ body, params }: any) => ({
        url: endPoints?.tasks,
        method: "POST",
        body:body,
        params: params,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    
  }),
});

export const { useCreateTaskMutation } = myMenteeSessionsApi;

import React, { useRef } from "react";
import RightSwiper from "assets/icons/RightSwiper.svg";
import RatingStarFilled from "assets/icons/RatingStarFilled.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CONTENT } from "CONSTANT/CONSTANT";

const Testimonials = () => {
  const sliderRef = useRef<any>(null);
  let settings = {
    className: "slider variable-width",
    infinite: true,
    variableWidth: true,
  };

  const randomBackgroundColor = (index: number) => {
    const color = [
      {
        bg: "bg-yellow-100",
      },
      {
        bg: "bg-green-100",
      },
      {
        bg: "bg-blue-100",
      },
      {
        bg: "bg-purple-100",
      },
    ];
    let returnColor = `${color[index].bg}`;
    return returnColor;
  };

  const Testimonials = CONTENT.TrialUpgrade.TESTIMONIALS;

  return (
    <div className="mt-40 px-6">
      <div>
        <p className="font-bold xl:text-3xl md:text-xl sm:text-lg text-sm">
          Here's why Preplaced is a proven solution:
        </p>
        <p className="text-gray-400 font-medium xl:text-[20px] lg:text-[18px] md:text-[15px] md:leading-[28px] sm:text-base text-[12px]">
          See it for yourself!
        </p>
      </div>
      <div className="flex sm:flex-row flex-col items-center bg-white divide-gray-300 sm:py-2 py-2 md:mt-6 mt-2 rounded-2xl">
        <p className="flex w-[49%] items-center justify-center text-center xl:text-[20px] lg:text-[18px] md:text-[15px] sm:text-xl text-xs font-bold text-indigo-500">
          <span>4.5</span><img src={RatingStarFilled} alt="" className="px-1 lg:px-2 w-[15px] h-[15px] sm:w-[25px] sm:h-[25px] lg:w-[30px] lg:h-[30px] xl:w-[35px] xl:h-[35px]" />{" "}
          <span className="font-medium text-gray-400">Rating</span>
        </p>
        <p className="w-[2%] text-[#d1d5db] text-4xl hidden sm:block">|</p>
        <p className="text-center w-[49%] sm:py-2 xl:text-[20px] lg:text-[18px] md:text-[15px] sm:text-xl text-xs font-bold text-indigo-500">
          5000+{" "}
          <span className="font-medium text-gray-400">
            hours of mentorship delivered
          </span>
        </p>
      </div>
      <div className="sm:mt-8 mt-[10px] relative">
        <Slider
          {...settings}
          ref={sliderRef}
          className="md:h-[350px] sm:h-[300px] h-[180px]"
          arrows={false}
        >
          {Testimonials.map((item: any, index: any) => {
            let color = randomBackgroundColor(index);
            return (
              <div
                className={`${color} sm:py-12 py-5 sm:pl-[38px] pl-[21px] sm:pr-[72px] pr-[30px] sm:rounded-lg rounded-sm lg:min-w-[833px] lg:max-w-[833px] md:min-w-[700px] md:max-w-[700px] sm:min-w-[600px] sm:max-w-[600px] xm:min-w-[320px] xm:max-w-[320px] min-w-[330px] max-w-[330px] md:h-[350px] sm:h-[300px] h-[180px] sm:mr-[35px] mr-[20px]`}
                key={index}
              >
                <div className="flex">
                  <div className="lg:flex-[0.15] sm:flex-[0.2] flex-[0.15]">
                    <img
                      src={item.PHOTO}
                      alt=""
                      className="rounded-full sm:w-[84px] sm:h-[84px] w-[20px] h-[20px]"
                    />
                  </div>
                  <div className="sm:space-y-5 space-y-5 lg:flex-[0.85] sm:flex-[0.8] flex-[0.85]">
                    <p className="text-gray-900 xl:text-[24px] md:text-[20px] sm:text-[18px] text-[12px] font-bold">
                      "{item.HEADING}"
                    </p>
                    <p className="text-gray-700 xl:text-[18px] md:text-[17px] sm:text-[13px] text-[9px] font-semibold">
                      {item.TEXT}
                    </p>
                    <div>
                      <p className="text-gray-900 xl:text-[24px] md:text-[20px] sm:text-[16px] text-[9px]  font-semibold">
                        {item.NAME}
                      </p>
                      <img
                        src={item.COMPANYPHOTO}
                        alt=""
                        className="sm:w-[80px] sm:h-[25px] h-[12px] object-contain"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        <div className="flex justify-between">
          <div
            className="cursor-pointer absolute sm:top-36 top-20 left-0 z-10"
            onClick={() => sliderRef.current.slickPrev()}
          >
            <img
              src={RightSwiper}
              alt=""
              className="rotate-180 md:w-[70px] md:h-[70px] sm:w-[50px] sm:h-[50px] w-[25px] h-[25px]"
            />
          </div>
          <div
            className="cursor-pointer absolute sm:top-36 top-20 right-0 z-10"
            onClick={() => sliderRef.current.slickNext()}
          >
            <img
              src={RightSwiper}
              alt=""
              className="md:w-[70px] md:h-[70px] sm:w-[50px] sm:h-[50px] w-[25px] h-[25px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

import React, { useState } from 'react'
import ListChecks from 'assets/icons/ListChecksWhite.svg'
import { useAppSelector } from 'state/hooks'
import PlanOfActionMentorCard from './PlanOfActionMentorCard'
import Plan1 from './Plan1'
import Plan2 from './Plan2'
import BothPlans from './BothPlans'
import { Modal } from 'common/Pop-Up-Modal/Modal'
import { sendAnalyticsToSegment } from 'api/analyticsV2'
import { newEventPropertyBuilder } from 'api/planning'
import { trackAnalytics } from 'api/analytics'

type Props = {}

const PlanOfAction = (props: Props) => {
  const [modalOpened, setmodalOpened] = useState(false)
  const initialUpgradeLaunchTime = useAppSelector(state => state.generalConfigs.trial_upgrade_opened_time)
  const menteeEmail = useAppSelector(state => state.generalConfigs.menteeEmail)
  const mentorEmail = useAppSelector(state => state.generalConfigs.mentorEmail)
  const menteeData = useAppSelector((state: any) => state.planner.menteeData)
  const [tellUsWhyOptions, settellUsWhyOptions] = useState<any>({
    "Price too high": false,
    "Not valuable": false,
    "Didn't find the session valuable": false,
    "I want to prepare by myself": false,
    "A mentor doesn't want to help": false,
    "Others": false,
  });

  const plannerInputAreas = useAppSelector(state => state.plannerInputAreas)
  return (
    <div className='md:px-6 flex flex-col gap-4'>
      <div className='md:px-0 px-4'><h1 className='font-bold md:text-4xl text-2xl'>Plan of action</h1>
        <p className='text-[#475467] md:text-base text-sm'>Here is the plan of action to make you interview ready and how the mentor will help you with the same.</p>
      </div>
      <div className='flex gap-4 bg-white shadow-lg rounded-lg px-8 py-6 items-center'>
        <img className='hidden md:block bg-[#1570ef] w-20 h-20 p-2 rounded-md' src={ListChecks} alt="" />
        <div>
          <div className='flex gap-2 items-center pb-2'>
            <img className='md:hidden bg-[#1570ef] w-5 h-5 p-1 rounded-md' src={ListChecks} alt="" />
            <p className='text-[#667085] font-medium text-sm'>Actionable steps for you:</p>
          </div>
          <ul className='px-4 list-disc md:text-lg font-medium'>
            {plannerInputAreas.actionableSteps.split('\n').map((step,i:number) => <li key ={i}>{step}</li>)}
          </ul>
        </div>
      </div>
      <PlanOfActionMentorCard />
      <div id="buy_mentor_recommendation" className='flex flex-col gap-6 md:px-0 px-4'>
        <h1 className='font-bold text-xl pb-6 max-w-2xl'>These are the services the mentor has recommended with them to make you interview-ready:</h1>
        {plannerInputAreas.packageRecommended === "IPP" && <Plan1 topRounded showTitleBar />}
        {plannerInputAreas.packageRecommended === "IS" && <Plan2 topRounded showTitleBar />}
        {plannerInputAreas.packageRecommended === "BOTH" && <BothPlans />}
      </div>

      <Modal open={modalOpened} handleClose={() => setmodalOpened(false)} handleCloseOutside={() => setmodalOpened(false)} showTopStickyTitleBar={false} className="max-w-md h-fit">
        <h3 className='text-lg font-semibold'>Please help us understand the reason for not upgrading so we can help you out with the same:</h3>
        <p className='text-[#667085] text-sm py-1'>You may select more than one:</p>
        <div className='flex flex-col gap-2 pt-4'>
          {Object.keys(tellUsWhyOptions).map((option: any,i:number) => <div key={i} className='flex gap-2 items-center font-medium'>
            <input type="checkbox" name="TellUsWhy" value={option} checked={tellUsWhyOptions[option]} className='rounded-sm' onChange={(e) => {
              const newTellUsOptions = { ...tellUsWhyOptions };
              newTellUsOptions[option] = e.target.checked;
              settellUsWhyOptions(newTellUsOptions);
            }} />
            <label htmlFor={option}>{option}</label>
          </div>)}
          <button 
            className='bg-[#101828] text-white font-medium p-2 rounded-lg mt-4' 
            onClick={() => {
              let selectedOptions = "";
              Object.keys(tellUsWhyOptions).forEach((option: any) => {
                if (tellUsWhyOptions[option]) {
                  selectedOptions = selectedOptions + option + ", ";
                }
              })
              const tellUsWhySubmittedEventBuilder = newEventPropertyBuilder({
                mentee_email:menteeEmail,
                mentor_email:mentorEmail,
                user_email:menteeData?.email,
                product_name: "Upgrade Report",
                event_name: "Tell Us Why Submitted",
                track_details:{
                  selectedOptions: selectedOptions.slice(0, selectedOptions.length - 2),
                },
                extra_track_properties: {
                  time_passed: Math.round(
                    (new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000)
                  ),
                },
              });
              trackAnalytics(tellUsWhySubmittedEventBuilder)
              // sendAnalyticsToSegment.track("Tell Us Why Submitted", {
              //   selectedOptions: selectedOptions.slice(0, selectedOptions.length - 2),
              //   time_passed: Math.round((new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000))
              // })
              setmodalOpened(false)
            }}
          >
            Submit
          </button>
        </div>
      </Modal>

      <div className='flex md:flex-row flex-col items-center justify-center font-medium gap-2 px-4'>
        <p className='md:text-[#667085] bg-white md:bg-transparent w-full md:w-fit text-center md:py-0 py-4 rounded-lg shadow-lg md:shadow-none'>Don't want to continue?
          <button onClick={() => {
            setmodalOpened(true);
            const notInterestedCtaClickedEventBuilder = newEventPropertyBuilder({
              mentee_email:menteeEmail,
              mentor_email:mentorEmail,
              user_email:menteeData?.email,
              product_name: "Upgrade Report",
              event_name: "Not Interested CTA Clicked",
              extra_track_properties: {
                time_passed: Math.round(
                  (new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000)
                ),
              },
            });
            trackAnalytics(notInterestedCtaClickedEventBuilder)
            // sendAnalyticsToSegment.track("Not Interested CTA Clicked", {
            //   time_passed: Math.round((new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000))
            // })
          }} className='text-[#0029F5]'>
            Tell us Why
          </button>
        </p>
        <p className='hidden md:block text-[#667085]'> | </p>
        <p className='md:text-[#667085]'>Still have doubts? <a href='tel:+917073210743' onClick={() => {
          const supportCtaClickedEventBuilder = newEventPropertyBuilder({
            mentee_email:menteeEmail,
            mentor_email:mentorEmail,
            user_email:menteeData?.email,
            product_name: "Upgrade Report",
            event_name: "Support CTA Clicked",
            extra_track_properties: {
              time_passed: Math.round(
                (new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000)
              ),
            },
          });
          trackAnalytics(supportCtaClickedEventBuilder)
          // sendAnalyticsToSegment.track("Support CTA Clicked", {
          //   time_passed: Math.round((new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000))
          // })
        }} className='text-[#0029F5]'>Call us now</a></p>
      </div>
    </div>
  )
}

export default PlanOfAction
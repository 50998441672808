import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface StepsState {
    // Step 2
    interview_readiness_mentor_error: boolean,
    areas_of_improvement_skills_error: boolean,
    // Step 3
    mentor_help_areas_error: boolean,
    // mentor_help_areas_practice_error: boolean,
    // mentor_help_areas_job_search_error: boolean,
    time_dedication_weekends_error: boolean,
    time_dedication_weekdays_error: boolean,
    time_to_interview_ready_error: boolean,
    month_wise_goals_error: boolean
}

const initialState: StepsState = {

    // Step 2
    interview_readiness_mentor_error: false,
    areas_of_improvement_skills_error: false,
    // Step 3
    mentor_help_areas_error: false,
    // mentor_help_areas_practice_error: false,
    // mentor_help_areas_job_search_error: false,
    time_dedication_weekends_error: false,
    time_dedication_weekdays_error: false,
    time_to_interview_ready_error: false,
    month_wise_goals_error: false
};

export const errorInputReducer = createSlice({
    name: "errorInput",
    initialState,
    reducers: {
        setErrorInput: (state: any, action: PayloadAction<any>) => {
            var key = action.payload.key;
            state[key] = action.payload.value
        },
    }
})

export const { setErrorInput } = errorInputReducer.actions;
export default errorInputReducer.reducer;
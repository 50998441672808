import { useEffect, useState } from 'react'

interface Props {
  children: any
  minTextLength: number
  className?: string
}

export const ReadMore: React.FC<Props> = ({ children, minTextLength, className }) => {
  const text = children
  const [isReadMore, setIsReadMore] = useState(false)

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }

  useEffect(() => {
    text.length > minTextLength ? setIsReadMore(true) : setIsReadMore(false)
  }, [text.length, minTextLength])

  return (
    <div className={className}>
      <p className=''>
        {isReadMore ? text.slice(0, minTextLength) + '...' : text}
        <span onClick={toggleReadMore}>
          {text.length > minTextLength && (
            <button className='ml-2 text-white text-[13px] leading-5 outline-none cursor-pointer'>
              {isReadMore ? 'read more' : ' show less'}
            </button>
          )}
        </span>
      </p>
    </div>
  )
}

import React from "react";
import ArrowRight from "assets/icons/ArrowRight.svg";

interface PlanningCardProps {
  id?: any;
  gifData: any;
  buttonText?: string;
  cardText: string;
  plannerDocUrl?: string;
}

const PlanningCard: React.FC<PlanningCardProps> = ({
  id,
  gifData,
  buttonText,
  cardText,
  plannerDocUrl
}) => {
  return (
    <div className="flex flex-col items-center">
      {
        id && <p className="text-left w-full text-white text-sm mb-2.5">STEP {id}</p>
      }
      <div className="bg-[#181818] rounded-lg p-5 flex flex-col items-center h-[420px]">
        <div>
          <img alt="" src={gifData} className="w-[720px] h-[290px] object-cover"/>
        </div>
        {
          plannerDocUrl && <a href={plannerDocUrl} target="_blank" rel="noreferrer" className="mt-10 text-white bg-blue-600 text-base rounded-full flex gap-x-3 items-center border-2 border-blue-600 px-4 py-1">
          <p>{buttonText}{" "}→</p>
        </a>
        }
        {
          cardText && <p className="mt-10 text-white">{cardText}</p>
        }
      </div>
    </div>
  );
};

export default PlanningCard;

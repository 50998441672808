import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { updateFirestore } from '../../api/planning';

export interface StepsState {
    step_one_section_one: boolean;
    step_one_section_two: boolean;
    step_one_section_three: boolean;
    step_two_section_one: boolean;
    step_two_section_two: boolean;
    step_three_section_one: boolean;
    step_three_section_two: boolean;
    step_three_section_three: boolean;
}

const initialState: StepsState = {
    step_one_section_one: false,
    step_one_section_two: false,
    step_one_section_three: false,
    step_two_section_one: false,
    step_two_section_two: false,
    step_three_section_one: false,
    step_three_section_two: false,
    step_three_section_three: false
};

export const activeSectionReducer = createSlice({
    name: "activeSection",
    initialState,
    reducers: {
        setActiveSection: (state: any, action: PayloadAction<any>) => {
            var key = action.payload.key;
            for (let property in state) {
                state[property] = false;
                // updateFirestore("scroll_to." + property, false)
            }
            state[key] = true;
            // updateFirestore("scroll_to." + key, true)
        },
        setScrollTo: (state: any, action: PayloadAction<any>) => {
            var scrollToPosition = action.payload.key
            state[scrollToPosition] = action.payload.value
        },
    }
})

export const { setActiveSection, setScrollTo } = activeSectionReducer.actions;
export default activeSectionReducer.reducer;
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PlannerInputAreas {
  interviewReadinessScore:number;  
  areasApartFromSkills:string; 
  packageRecommended: string;
  actionableSteps: string;
  selectedPackages: any;
  IppPackage: any;
  selectedSkills:any;
  timeline: string;
  notes: string;
  resumeUrl: string;
}

const initialState: PlannerInputAreas = {
  interviewReadinessScore:0,
  areasApartFromSkills:"",
  packageRecommended : "",
  actionableSteps: "",
  selectedPackages: [],
  IppPackage: {},
  selectedSkills: [],
  timeline: "",
  notes: "",
  resumeUrl: "",
};

export const plannerInputAreasReducer = createSlice({
    name: "plannerInputAreas",
    initialState,
    reducers:{
      setTrialInputAreas:(state: any, action:PayloadAction<any>) => {
        var trailDocInputAreas = action.payload.key;
        state[trailDocInputAreas] = action.payload.value;
      },
    }
})

export const { setTrialInputAreas } = plannerInputAreasReducer.actions;
export default plannerInputAreasReducer.reducer;



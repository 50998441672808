import React from 'react'
import Logo from 'assets/logo/Logo.svg'
import { useAppSelector } from 'state/hooks';
import { Link } from 'react-scroll';
import { sendAnalyticsToSegment } from 'api/analyticsV2';
import { newEventPropertyBuilder } from 'api/planning';
import { trackAnalytics } from 'api/analytics';

type Props = {}

const Navbar = (props: Props) => {
  const initialUpgradeLaunchTime = useAppSelector(state => state.generalConfigs.trial_upgrade_opened_time)
  const menteeEmail = useAppSelector(state => state.generalConfigs.menteeEmail)
  const mentorEmail = useAppSelector(state => state.generalConfigs.mentorEmail)
  const menteeData = useAppSelector((state: any) => state.planner.menteeData)
  const trackBuyNowAnalytics = () => {
    const headerCtaClickedEventBuilder = newEventPropertyBuilder({
      mentee_email:menteeEmail,
      mentor_email:mentorEmail,
      user_email:menteeData?.email,
      product_name: "Upgrade Report",
      event_name: "Header CTA Clicked",
      extra_track_properties: {
        time_passed: Math.round(
          (new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000)
        ),
      },
    });
    trackAnalytics(headerCtaClickedEventBuilder)
    // sendAnalyticsToSegment.track("Header CTA Clicked", {
    //   time_passed: Math.round((new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000))
    // })
  }

  const mentorName = useAppSelector(state => state.planner.mentorData.name);
  const menteeName = useAppSelector(state => state.planner.menteeData.candidateName)
  return (
    <div className='bg-[#EEF4FF] p-5'>
      <img src={Logo} alt="Preplaced Logo" className='pb-2' />
      <div className='flex gap-4 justify-between items-center'>
        <div>
          <h2 className='font-bold text-xl md:text-2xl'>Hi {menteeName},</h2>
          <p className='text-sm md:text-lg'>Here is your detailed report analysis that you took with {mentorName} 🚀</p>
        </div>
        <Link to='buy_mentor_recommendation' offset={-50} duration={300} smooth
          className='text-center cursor-pointer text-sm md:text-xl whitespace-nowrap bg-black text-white h-fit py-2 md:px-6 px-2 rounded-lg lg:mr-10'
          onClick={trackBuyNowAnalytics}>Buy Now</Link>
      </div>
    </div>
  )
}

export default Navbar
import { menteeData, mentorData, servicesData } from "../../types/Plan";

export const planInfoMenteeProfileDataMapper = (userData: any) => {
  let data = userData[0];
  let mentorEmail = data.mentor?.email || "";

  let prefilledPlanData = new menteeData(
    data?.name,
    data?.goals,
    data?.longTermMentorshipHelp,
    data?.problemsForGoals,
    data?.userCategory,
    data?.currentCompany,
    data?.currentRole,
    data?.collegeDegree,
    data?.collegeName,
    data?.resumeUrl ? data?.resumeUrl : data?.resumeUrl,
    data?.recordId,
    "data?.expectedPreparationDuration",
    data?.targetCompanies,
    data?.targetDomains,
    data?.upcomingInterview,
    data?.workExperience,
    data?.painPoints,
    data?.targetRole,
    data?.email,
    data?.interviewReadinessScore,
    data["efforts&Progress"],
    "data?.graduatingIn",
    mentorEmail,
    data?.photoUrl,
    data?.pastRole,
    data?.pastCompany
  );
  const plainPrefilledPlanData = Object.assign({}, prefilledPlanData);
  // console.log("Mentee Data: ",plainPrefilledPlanData)
  return plainPrefilledPlanData;
};

export const planInfoMentorProfileDataMapper = (userData: any) => {
  let data = userData;

  let careerJourneyJson = JSON.parse(data?.careerJourneyJson);
  let prefilledPlanData = new mentorData(
    data?.recordId,
    data?.name,
    data?.ltmJson,
    data?.email,
    data?.photoUrl,
    data?.domain,
    data?.linkedInUrl,
    data?.currentCompany,
    data?.currentDesignation,
    data?.pastCompanies,
    data?.totalExperience,
    data?.averageSessionRating,
    data?.totalSessions,
    data?.totalReviews,
    data?.profileBio,
    data?.skillset,
    data?.languagePreference,
    careerJourneyJson,
    data?.publicProfileId,
    data?.referralPreference,
    data?.project,
    data?.teaching,
    data?.sessionFrequency,
    data?.followupFrequency,
    data?.responsivenessOnPreplacedChat,
    data?.adhocCalls,
    data?.monthlyMentorshipPrice,
    data?.longTermPitch,
    data?.menteePersona,
    data?.menteeLimit,
    data?.menteeAspirations,
    data?.expectationsFromMentees,
    data?.monthlyTrialSettings,
    data?.technicalSkillsJson,
    data?.mentoringSkillsJson
  );
  const plainPrefilledPlanData = Object.assign({}, prefilledPlanData);
  // console.log("Mentor final data: ",plainPrefilledPlanData)
  return plainPrefilledPlanData;
};

export const servicesConfigToOptedInServicesMapper = (
  allServicesConfigList: any,
  optedInServicesList: any
) => {
  let mentorServicesData: {}[] =
    optedInServicesList &&
    optedInServicesList.map((optedService: any, optedServiceIndex: number) => {
      for (
        let serviceConfigIndex = 0;
        serviceConfigIndex < allServicesConfigList.length;
        ++serviceConfigIndex
      ) {
        // let optedServiceData:any
        if (
          allServicesConfigList[serviceConfigIndex].serviceName ===
          optedService?.serviceName
        ) {
          let optedServiceData = new servicesData(
            optedService?.serviceName,
            allServicesConfigList[serviceConfigIndex]["cardDescription"],
            allServicesConfigList[serviceConfigIndex]["callDuration"],
            allServicesConfigList[serviceConfigIndex]["guidelines"],
            allServicesConfigList[serviceConfigIndex]["whyDescription"],
            allServicesConfigList[serviceConfigIndex]["whyPlaceholder"],
            0,
            optedService?.totalPricing,
            optedService?.mentorPayoutPricing
          );
          return optedServiceData;
        }
      }
    });
  mentorServicesData = mentorServicesData.filter((optedService: any) => {
    if (optedService?.serviceName !== "Free Trial Session") {
      return true;
    }
  });
  // console.log(mentorServicesData)
  return mentorServicesData;
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateProps {
  accordionActiveId: string;
}

const initialState: initialStateProps = {
  accordionActiveId: "",
};

const accordionSlice = createSlice({
  name: "accordion",
  initialState,
  reducers: {
    setAccordionActiveStateId: (state: any, action: PayloadAction<any>) => {
      state.accordionActiveId = action.payload;
    },
  },
});

export const { setAccordionActiveStateId } = accordionSlice.actions;
export default accordionSlice.reducer;

import { sendAnalyticsToSegment } from "api/analyticsV2";
import Header from "../header/header";

const Text = ({ children }: any) => {
  return (
    <div className="flex gap-2.5 text-xs font-normal  leading-[18px]">
      <div className="self-start">✅</div>
      <div className="text-gray-500">{children}</div>
    </div>
  );
};

type NextMeetScreenType = {
  menteeDetails: any;
  mentorDetails: any;
};

enum ButtonNamesEnum {
 SET_WEEKLU_SCHEDULE = "Set a Weekly Schedule",
 SCHEDULE_ONLY_NEXT_SESSION = "Schedule Only Next Session"
}

const NextMeetScreen = ({
  menteeDetails,
  mentorDetails,
}: NextMeetScreenType) => {
  const handleClick = (buttonName: ButtonNamesEnum) => {
    const url = buttonName === ButtonNamesEnum.SCHEDULE_ONLY_NEXT_SESSION ? "https://mentor.preplaced.in/my_sessions" : `https://mentor.preplaced.in/my_mentees/${menteeDetails?.recordId}/sessions?openScheduler=true`
    window.open(url, "_blank");
    try {
      sendAnalyticsToSegment.track("Button Clicked", {
        button_name: buttonName,
        product: "planner",
      });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className="w-full pb-6 bg-white rounded-xl shadow flex-col justify-start items-start gap-6 inline-flex">
      {/* Header */}
      <Header
        menteeDetails={menteeDetails}
        mentorDetails={mentorDetails}
      />
      {/* Body */}
      <div className="w-full px-6 flex-col justify-start items-start gap-6 inline-flex">
        <div className="w-full flex-col justify-start items-start gap-3 flex">
          <div className="self-stretch flex-col justify-start items-start gap-6 flex">
            <div className="self-stretch flex-col justify-start items-start gap-4 flex">
              <div className="self-stretch text-slate-700 text-base font-semibold  leading-normal">
                2. When you are meeting next! Do so by...
              </div>
              <div className="self-stretch justify-start items-start gap-3 inline-flex">
                <div className="grow shrink basis-0 self-stretch p-4 bg-white rounded border border-gray-200 flex-col justify-between items-start inline-flex">
                  <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                    <div className="self-stretch text-slate-700 text-xs font-semibold  leading-tight">
                      Scheduling Next Session
                    </div>
                    <div className="flex flex-col gap-3">
                      <Text>
                        Helps mentee have a{" "}
                        <span className="font-semibold">clear deadline</span> to
                        complete tasks.
                      </Text>

                      <Text>
                        <span className="font-semibold">Option to change</span>{" "}
                        the session date to another
                      </Text>
                    </div>
                  </div>
                  <div
                    onClick={() => handleClick(ButtonNamesEnum.SCHEDULE_ONLY_NEXT_SESSION)}
                    className="cursor-pointer self-stretch px-4 py-2.5 bg-white rounded shadow border border-gray-300 justify-center items-center gap-2 inline-flex"
                  >
                    <div className="text-center text-slate-700 text-xs font-semibold  leading-[18px]">
                      Schedule Only Next Session
                    </div>
                  </div>
                </div>
                <div className="grow shrink basis-0 p-4 bg-slate-50 rounded border border-sky-200 flex-col justify-start items-start gap-6 inline-flex">
                  <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                    <div className="self-stretch text-slate-700 text-xs font-semibold  leading-tight">
                      Setting up a Session Schedule
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-3 flex">
                      <Text>
                        Helps mentee have a{" "}
                        <span className="font-semibold">clear deadline</span> to
                        complete tasks
                      </Text>

                      <Text>
                        <span className="font-semibold">Option to change</span>{" "}
                        the whole schedule anytime
                      </Text>

                      <Text>
                        <span className="font-semibold">
                          Removes back and forth
                        </span>{" "}
                        every time to schedule a session
                      </Text>

                      <Text>
                        Makes sure your mentee{" "}
                        <span className="font-semibold">stays connected</span>{" "}
                        with you throughout the program
                      </Text>
                    </div>
                  </div>
                  <div
                    onClick={() => handleClick(ButtonNamesEnum.SET_WEEKLU_SCHEDULE)}
                    className="cursor-pointer self-stretch px-6 py-2.5 bg-zinc-950 rounded shadow border border-zinc-950 justify-center items-center gap-2 inline-flex"
                  >
                    <div className="text-center text-white text-xs font-semibold  leading-[18px]">
                      Set a Weekly Schedule
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextMeetScreen;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchConfigurations } from "../../api/planning";

import { RootState } from "../store";

const initialState:any = {
    configurations: {},
    status: 'idle',
    error: ''
}

const configurationsSlice = createSlice({
    name: 'configurations',
    initialState,
    reducers: {
      setSkillOptions: (state: any, action: PayloadAction<any>) => {
        state.configurations.skill_options = action.payload
      },
    },
    extraReducers(builder) {
        builder.addCase(fetchConfigurations.pending, (state, action) => {
            state.error = ''
            state.status = 'loading'
          })
          .addCase(fetchConfigurations.fulfilled, (state, action) => {
            state.error = ''
            state.status = 'succeeded'
            state.configurations = action.payload.data
          })
          .addCase(fetchConfigurations.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
          })
    },
  })


export default configurationsSlice.reducer
export const { setSkillOptions } = configurationsSlice.actions
export const selectConfigurations = (state:RootState) => state.configurations

// Plan 2 are Individual Sessions
import { newEventPropertyBuilder, updateFirestore } from 'api/planning';
import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'state/hooks'
import getPackageLogo from 'utils/package-details/getPackageLogo';
import config from "env.config";
import { sendAnalyticsToSegment } from 'api/analyticsV2';
import { trackAnalytics } from 'api/analytics';

type Props = {
  topRounded?: boolean
  showTitleBar?: boolean
  className?: string
}

const Plan2 = (props: Props) => {
  const selectedPackages = useAppSelector(state => state.plannerInputAreas.selectedPackages);
  const packageRecommended = useAppSelector(state => state.plannerInputAreas.packageRecommended);
  const menteeData = useAppSelector((state: any) => state.planner.menteeData)
  const [totalPakages, settotalPakages] = useState(0)
  const [totalPrice, settotalPrice] = useState(0)
  const [totalDuration, settotalDuration] = useState("")
  useEffect(() => {
    let price = 0;
    let sessions = 0;
    let duration = 0;

    selectedPackages.forEach((session: any) => {
      price = price + (session.price * session.noOfRecommendation);
      sessions = sessions + session.noOfRecommendation;
      duration = duration + (session.callDuration.split('min')[0] * session.noOfRecommendation);
    })

    settotalPakages(sessions);
    settotalPrice(price);

    if (duration > 60) {
      duration = duration / 60;
      settotalDuration(duration + " hrs")
    } else {
      settotalDuration(duration + " mins")
    }
  }, [selectedPackages])

  const mentorData = useAppSelector(state => state.planner.mentorData)
  const initialUpgradeLaunchTime = useAppSelector(state => state.generalConfigs.trial_upgrade_opened_time)
  const menteeEmail = useAppSelector(state => state.generalConfigs.menteeEmail)
  const mentorEmail = useAppSelector(state => state.generalConfigs.mentorEmail)
  const pathName = window.location.pathname.split("/");
  const sessionId: any = pathName[pathName.length - 1];
  return (
    <div className={props.className}>
      {props.showTitleBar && <div className='flex justify-between pb-2'>
        <p className='text-[#667085] text-sm'>Sessions Recommended:</p>
        <p className='text-sm md:block hidden'>Total Sessions: {totalPakages} | Hours: {totalDuration} | Total: Rs {totalPrice}</p>
      </div>}
      <div className={`bg-white ${props.topRounded && "rounded-t-lg"} rounded-b-lg px-4 md:px-8 py-6 shadow-lg w-full`}>
        {selectedPackages.map((session: any) => <>
          <div className='md:flex justify-between'>
            <div className='flex gap-2 sm:gap-4 lg:pr-14 xl:pr-20'>
              <img src={getPackageLogo(session.name)} alt="" className='w-10 h-10 p-2 border-[1px] rounded-lg' />
              <div>
                <h3 className='text-lg font-semibold'>{session.name}</h3>
                <p className='max-w-sm lg:max-w-xl text-sm md:text-base'>{session.description}</p>
              </div>
            </div>
            <div className='flex grow justify-between items-center mt-4 p-2 bg-[#f2f4f7] rounded-t-lg md:bg-transparent font-medium'>
              <p>{session.callDuration}</p>
              <p className='border-[1px] rounded-full px-4 h-fit'>Qty: {session.noOfRecommendation}</p>
              <p className='font-semibold'>Rs {session.price}</p>
            </div>
          </div>
          <hr className='md:my-4 mb-4' />
        </>)}
        <div className='md:flex justify-between md:pl-14'>
          <p className='flex flex-col md:flex-row md:gap-2 md:text-lg font-medium pb-2'>Total <span className='text-[#667085]'>({totalPakages} {totalPakages>1 ? "sessions" : "session"})</span></p>
          <p className='font-bold text-xl'>Rs {totalPrice}</p>
        </div>
        <a 
          className='flex justify-center text-xl bg-[#03020a] text-white w-full p-3 mt-6 hover:shadow-xl rounded-lg'
          href = {`${config.REACT_APP_PREPLACED_APP_URL}/profile/${mentorData.publicProfileId}?session_id=${sessionId}&c=true`}
          onClick={async() => {
            await updateFirestore("selectedPlanFromUpgrade", "IS", true)
            const buyNowClickedEventBuilder = newEventPropertyBuilder({
              mentee_email:menteeEmail,
              mentor_email:mentorEmail,
              user_email:menteeData?.email,
              product_name: "Upgrade Report",
              event_name: "Buy Now Clicked",
              track_details:{
                plan: packageRecommended,
              plan_price: totalPrice,
              },
              extra_track_properties: {
                time_passed: Math.round(
                  (new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000)
                ),
              },
            });
            trackAnalytics(buyNowClickedEventBuilder)
            // sendAnalyticsToSegment.track("Buy Now Clicked", {
            //   plan: packageRecommended,
            //   plan_price: totalPrice,
            //   time_passed: Math.round((new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000))
            // })
        }}
        >
          Start Plan
        </a>
      </div>
    </div>
  )
}

export default Plan2
import { useAppSelector } from 'state/hooks';
import MentorStepsSection from './MentorStepsSection';
import MenteeStepsSection from './MenteeStepsSection';

const RightSide = () => {
  const ROLE = useAppSelector((state) => state.generalConfigs.join_role);
  return (
    <div className='p-10 max-h-full overflow-y-scroll thin-scrollbar'>
      {ROLE === "mentor" ? <MentorStepsSection /> : <MenteeStepsSection />}
    </div>
  )
}

export default RightSide
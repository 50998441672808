import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { Viewer, Worker } from "@react-pdf-viewer/core";
// import "@react-pdf-viewer/core/lib/styles/index.css";
import CrossCloseIcon from "../../assets/icons/CrossCloseIcon.svg";
import { PDFModalProps } from "./pdfmodal.types";

const PDFModal = ({
  open,
  handleClose,
  className,
  pdfUrl,
  handleCloseOutside,
  modalTitle,
  children,
}: PDFModalProps) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={handleCloseOutside}
      >
        <div className="flex justify-center h-screen items-center p-5">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 rounded-lg bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`${className} relative inline-block align-top bg-slate-50 rounded-lg text-left shadow-xl transform transition-all`}
            >
              <div
                className={`flex items-center justify-between px-4 bg-slate-50 sticky top-0 z-30`}
              >
                <Dialog.Title
                  as="h3"
                  className="text-sm font-medium leading-6 text-gray-700"
                >
                  {modalTitle}
                </Dialog.Title>
                <button
                  onClick={() => handleClose()}
                  className="hover:bg-slate-100 p-3 text-xl rounded-lg cursor-pointer active:bg-slate-300 duration-300"
                >
                  <img src={CrossCloseIcon} alt="" className="w-2 h-2" />
                </button>
              </div>
              {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
                <Viewer fileUrl={pdfUrl} />
              </Worker> */}
              <iframe src={pdfUrl} title="Pdf" className="w-full h-full" />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
  //   <>
  //     {fileUrl && fileUrl != '' ?
  //       (<button
  //         className="font-medium xl:text-sm text-xs text-primary-blue"
  //         onClick={() => {
  //           handleOpen()
  //           const eventBuilder = eventPropertyBuilder({
  //             track_details:{
  //                 ...menteeData
  //             },
  //             product_name:"Trial Session Doc",
  //             role:params.role,
  //             event_name:"Resume Opened",
  //             extra_track_properties: {
  //                 time_passed:Math.round((new Date().getTime() - initialTrialLaunchTime) / (60 * 1000))
  //             }
  //           })
  //           // trackAnalytics(eventBuilder);
  //         }
  //         }
  //       >
  //         {buttonText}
  //       </button>
  //       ) : '-'}
  //     {open && ReactDOM.createPortal(modalBody(), document.body)}
  //   </>
  // );
};

export default PDFModal;

import React, { useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";

interface Props {
  children?: React.ReactNode;
}

const ResizeOverlay: React.FC<Props> = ({ children }) => {
  const [appWidth, setAppWidth] = useState(787)
  const appContainerRef = useRef<any>()

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      // TODO: praty: add debounce here
      setAppWidth(entries[0].contentRect.width)
    })
    observer.observe(appContainerRef.current)
    return () => appContainerRef.current && observer.unobserve(appContainerRef.current)
  }, [])

  return (
    <main ref={appContainerRef}>
      {appWidth < 786 ?
        <Fade className="flex items-center justify-center absolute top-0 -bottom-[7rem] w-full h-[100vh] bg-[#161616] backdrop-blur-sm z-50">
          <div className="bg-[#222] bg-opacity-40 p-5 rounded-lg text-white">
            <p className="text-center text-2xl font-semibold">
              Your browser is too small
            </p>
            <p className="text-center text-sm font-normal">
              Resize your browser to be atleast 768px
              <br />
              to get back into appropriate view
            </p>
          </div>
        </Fade> :
        children
      }
    </main>
  );
};

export default ResizeOverlay;

import React from 'react'
import ProgressBar from './ProgressBar'
import BoundingBox from 'assets/icons/BoundingBox.svg'
import CodeSandboxLogo from 'assets/icons/codesandboxLogo.svg'
import Cube from 'assets/icons/cube.svg'
import BulbIcon from 'assets/icons/BulbIconWhite.svg'
import { useAppSelector } from 'state/hooks'
import Skill from 'routes/trial-session/step1/Components/Skill'

type Props = {
  interviewReadinessScore: number
}

const ReportAnalysis = ({ interviewReadinessScore }: Props) => {
  const plannerInputAreas = useAppSelector(state => state.plannerInputAreas)
  return (
    <div className='px-2 md:px-6 flex flex-col gap-4'>
      <div className='px-4 md:px-0'><h1 className='font-bold md:text-4xl text-2xl'>Report Analysis</h1>
        <p className='text-[#475467] md:text-base text-sm'>Here is a detailed report analysis to help you identify the current level and areas you need to improve by</p>
      </div>
      <div className='flex flex-col gap-4 bg-white rounded-lg shadow-xl py-6 px-8'>
        <p className='font-semibold'>Your interview readiness level indicates:</p>
        <div className='flex justify-between'>
          <h1 className={`text-4xl font-bold ${interviewReadinessScore < 4 ? "text-[#D92D20]" : `${interviewReadinessScore < 8 ? "text-[#F79009]" : "text-[#12B76A]"}`} pl-2`}>{interviewReadinessScore}/10</h1>
          <div>
            {interviewReadinessScore < 4 ?
              (<img src={BoundingBox} alt="" className="bg-[#D92D20] w-12 h-12 p-2 rounded-full self-center" />)
              : (interviewReadinessScore < 8 ?
                (<img src={Cube} alt="" className="bg-[#F79009] w-12 h-12 p-2 rounded-full self-center" />)
                :
                (<img src={CodeSandboxLogo} alt="" className="bg-[#12B76A] w-12 h-12 p-2 rounded-full self-center" />))
            }
          </div>
        </div>
        <ProgressBar bgcolor={interviewReadinessScore < 4 ? "#D92D20" : `${interviewReadinessScore < 8 ? "#F79009" : "#12B76A"}`} progress={10 * interviewReadinessScore} height={12} />
        <div className='text-xs md:text-sm'>
          {interviewReadinessScore < 4 ? (
            <div className='bg-[#FEF3F2] px-3 py-2 rounded-lg ease-in-out duration-200'>
              <p className='text-[#B42318] font-medium'>
                You are {10 - interviewReadinessScore} steps away from your goal. On a beginner level for your target role.
              </p>
            </div>
          ) : interviewReadinessScore < 8 ? (
            <div className='bg-[#FFFAEB] px-3 py-2 rounded-lg ease-in-out duration-200'>
              <p className='text-[#B54708] font-medium'>
                You are {10 - interviewReadinessScore} steps away from your goal. On an intermediate level for your target role.
              </p>
            </div>
          ) : (
            <div className='bg-[#ECFDF3] px-3 py-2 rounded-lg ease-in-out duration-200'>
              <p className='text-[#027A48] font-medium'>
                You are {10 - interviewReadinessScore} steps away from your goal. On an advanced level for your target role.
              </p>
            </div>
          )}
        </div>
      </div>
      <div className='rounded-lg py-6 px-8 shadow-xl bg-white'>
        <div className='flex gap-6 items-center'>
          <img src={BulbIcon} alt="" className='hidden md:block bg-[#7f56d9] p-4 w-20 h-20 rounded-md'></img>
          <div>
            <div className='flex gap-2'>
              <img src={BulbIcon} alt="" className='md:hidden bg-[#7f56d9] p-1 w-5 h-5 rounded-md'></img>
              <p className='text-[#667085] font-medium pb-4 text-sm'>Skills you need to work upon:</p>
            </div>
            <Skill skills={plannerInputAreas.selectedSkills} />
          </div>
        </div>
        {plannerInputAreas.areasApartFromSkills && <><hr className='m-4' />
          <p className='text-[#667085] font-medium pb-1 text-sm md:text-sm'>Areas apart from skills that the mentee needs to work upon:</p>
          <p className='font-medium text-lg'>{plannerInputAreas.areasApartFromSkills}</p></>}
      </div>
    </div>
  )
}

export default ReportAnalysis
import LackingPlan from "../assets/icons/LackingPlan.svg";
import Shortlisted from "../assets/icons/Shortlisted.svg";
import ExpressYourself from "../assets/icons/ExpressYourself.svg";
import DespiteEfforts from "../assets/icons/DespiteEfforts.svg";
import OpenPlannerGif from "../assets/gifs/OpenPlanner.gif";
import ShareScreenGif from "../assets/gifs/ShareScreen.gif";
const params = Object.fromEntries(
  new URLSearchParams(window.location.search).entries()
);
export const CONTENT: any = {
  COMMON: {
    STEP1: {},
    STEP2: {},
    STEP3: {},
    STEP4: {},
  },
  MENTEE: {
    STEP1: {
      HEADING: "About you",
      EXPAND: {
        SECTION_1: {
          HEADING: "Current Situation",
          SUB_HEADING: "",
          TEXT_BOX: {
            HEADING: "",
            BULLET_POINTS: ["", ""],
          },
        },
        SECTION_2: {
          HEADING: "Pain Points",
          SUB_HEADING: "",
          TEXT_BOX: {
            HEADING: "Learn about mentee's",
            BULLET_POINTS: ["", ""],
          },
        },
        SECTION_3: {
          HEADING: "Target & Timeline",
          TEXT_BOX: {
            HEADING: "",
            SUB_HEADING: "",
          },
        },
      },
    },
    STEP2: {
      HEADING: "Your Interview Preparation Level",
      EXPAND: {
        SECTION_1: {
          HEADING: "Interview readiness according to you",
          SUB_HEADING: "",
          TEXT_BOX: {
            HEADING: "",
            BULLET_POINTS: [""],
          },
        },
        SECTION_2: {
          HEADING: "Interview readiness as per mentor",
          SUB_HEADING: "",
          TEXT_BOX: {
            HEADING: "",
            BULLET_POINTS: [""],
          },
        },
      },
    },
    STEP3: {
      HEADING: "How the mentor will help you with your next steps",
      EXPAND: {
        SECTION_1: {
          HEADING: "What all will the mentor help you with",
          SUB_HEADING: "",
          TEXT_BOX: {
            HEADING: "",
            DISCUSSION: ["", ""],
          },
          OPTIONS: {
            GUIDANCE: [
              "Detailed roadmap & plan",
              "Regular Syncups",
              "Detailed evaluations & feedback",
              "Explaining interview process",
              "Consulting session",
              "Concept building session",
            ],
            PRACTICE: [
              "Curated resources",
              "Mini assignments",
              "Mini projects",
              "HR/Behavioural Mock interview",
              "Technical Mock interview",
            ],
            JOB_SEARCH: [
              "Resume building",
              "Job application assistance",
              "Profile building",
              "Linkedin Review",
              "Referral",
            ],
          },
        },
        SECTION_2: {
          HEADING: "Hours you can dedicate daily during",
          SUB_HEADING: "",
        },
        SECTION_3: {
          HEADING: "It will take  $  for you to be interview ready",
          SUB_HEADING: "",
          TEXT_BOX: {
            HEADING: "",
            DISCUSSION: ["", "", "", ""],
          },
        },
      },
    },
    STEP4: {},
    STEP5: {
      UPGRADE_BUTTON: "View Upgrade Plan",
    },
  },
  MENTOR: {
    STEP1: {
      HEADING: "Understand Your Mentee",
      EXPAND: {
        SECTION_1: {
          HEADING: "Current Situation",
          SUB_HEADING: "Understanding mentee's background",
          TEXT_BOX: {
            HEADING: "Things you can ask the mentee",
            BULLET_POINTS: [
              "Make the mentee comfortable",
              "Introduce yourself as a mentor",
              "Discuss mentee's career journey",
            ],
          },
        },
        SECTION_2: {
          HEADING: "Pain Points",
          SUB_HEADING: "Understand mentee's problem & struggle",
        },
        SECTION_3: {
          HEADING: "Target & Timeline",
          SUB_HEADING: "Learn about mentee's goal & time in hand",
          TEXT_BOX: {
            HEADING: "Learn about mentee",
            BULLET_POINTS: [
              "Goals & aspirations",
              "Motivations & their “Why?”",
              "Preparation timeline",
            ],
          },
        },
      },
    },
    STEP2: {
      HEADING: "Evaluate Mentee's Current Preparation Level",
      EXPAND: {
        SECTION_1: {
          HEADING: "Interview readiness as per mentee",
          SUB_HEADING: "",
          TEXT_BOX: {
            HEADING: "Discussion Points",
            BULLET_POINTS: [
              "The “why” behind mentee's self-rating",
              "Mentee's current preparation methods & routine",
            ],
          },
        },
        SECTION_2: {
          HEADING: "Interview readiness according to you",
          SUB_HEADING: "",
          TEXT_BOX: {
            HEADING: "Discussion Points",
            BULLET_POINTS: [
              "Mentee's current standing as per you",
              "Areas to work upon to get interview-ready",
            ],
          },
        },
      },
    },
    STEP3: {
      HEADING: "How you will help the mentee with their next steps",
      EXPAND: {
        SECTION_1: {
          HEADING: "How will you help the mentee",
          SUB_HEADING: "",
          TEXT_BOX: {
            HEADING: "Discussion Points",
            DISCUSSION: [
              "How starting preparing now with you will be helpful",
              "How you will be guiding them from their current preparation stage to being interview ready",
            ],
          },
          OPTIONS: {
            GUIDANCE: [
              "Detailed roadmap & plan",
              "Regular Syncups",
              "Detailed evaluations & feedback",
              "Explaining interview process",
              "Consulting session",
              "Concept building session",
            ],
            PRACTICE: [
              "Curated resources",
              "Mini assignments",
              "Mini projects",
              "HR/Behavioural Mock interview",
              "Technical Mock interview",
            ],
            JOB_SEARCH: [
              "Resume building",
              "Job application assistance",
              "Profile building",
              "Linkedin Review",
              "Referral",
            ],
          },
        },
        SECTION_2: {
          HEADING: "Hours mentee can dedicate daily during",
          SUB_HEADING: "",
        },
        SECTION_3: {
          HEADING: "It will take  $  for the mentee to be interview ready",
          SUB_HEADING: "",
          TEXT_BOX: {
            HEADING: "Discussion points",
            DISCUSSION: [
              "Time to be interview-ready",
              "Top level preparation plan",
              "Goals for the upcoming month",
              "How you as a mentor wil help achieve the goals",
            ],
          },
        },
      },
    },
    STEP4: {
      HEADING: "",
      EXPAND: {},
    },
    STEP5: {
      UPGRADE_BUTTON: "Discuss Upgrade Plan",
    },
  },
  TrialUpgrade: {
    DIFFICULTIES: [
      {
        Image: LackingPlan,
        Text: "Lacking guidance and plan",
      },
      {
        Image: DespiteEfforts,
        Text: "Not being able progress despite putting efforts",
      },
      {
        Image: Shortlisted,
        Text: "Facing difficulty getting shortlisted",
      },
      {
        Image: ExpressYourself,
        Text: "Not being able to express yourself duing interviews",
      },
    ],
    IPP: {
      HEADINGS: [
        [
          "Makes you prepare faster and the right way",
          "Plan based on your current preparation level, goals & timeline",
          "With clear actionable tasks & references to top resources",
        ],
        [
          "Improves your most profitable assets. Soft skills & Resume",
          "Interview practice & feedback to build your interviewing soft skills",
          "Resume rounds & profile improvements to achieve that dream call",
        ],
        [
          "Make sure you're on the right path. Always",
          "Unblock and resolve all your doubts, problems & progress blockers",
          "Keeps you motivated, consistent, accountable and on track",
        ],
        [
          "Make you a master of your skills. Just like them",
          "Rigorous practice to cover every concept & interview question",
          "In-depth sessions to overcome all your skill gaps & weaknesses",
        ],
      ],
    },
    TESTIMONIALS: [
      {
        HEADING: "The interview preparation program structure is great!",
        TEXT: "My mentor was very knowledgeable. His feedback on my software mock interview was extremely useful.",
        NAME: "Venkat Sakhineti",
        COMPANY: "Careem Dubai",
        COMPANYPHOTO:
          "https://assets.website-files.com/5fe47ec3e084a85e759e361f/63054af91b89e385059cc73c_6263c4298b3cab7b1523fd5d_1200px-Careem_logo.svg-p-500.webp",
        PHOTO:
          "https://assets.website-files.com/5fe47ec3e084a85e759e361f/63054af6bb86c25805787d3b_6251614677dfcae54fce264e_Venkat%20Sakhineti.webp",
      },
      {
        HEADING:
          "The interview preparation program at Preplaced topped my expectations",
        TEXT: "The team has carefully selected the interviewers and mentors who are from our choice of the company.",
        NAME: "Aman Masih",
        COMPANY: "JP Morgan",
        COMPANYPHOTO:
          "https://assets.website-files.com/5fe47ec3e084a85e759e361f/63054ad20844ab9ab3564e17_6263c4684a12d7635bd7b591_2000px-J_P_Morgan_Logo_2008_1.svg_-p-500.webp",
        PHOTO:
          "https://assets.website-files.com/5fe47ec3e084a85e759e361f/63054ad02753637391064b52_62516644ac49bc2eea17668c_Aman%20Masih.webp",
      },
      {
        HEADING: "The best interview preparation platform out there!",
        TEXT: "It was super-helpful to practise my software interviews with a Walmart interviewer. Kudos to the team!",
        NAME: "Shubham Sharma",
        COMPANY: "Mobileum",
        COMPANYPHOTO:
          "https://assets.website-files.com/5fe47ec3e084a85e759e361f/63054ab0adf982990bc18a42_6263c3ed3db7196a87d0bbbb_logo-dark.webp",
        PHOTO:
          "https://assets.website-files.com/5fe47ec3e084a85e759e361f/63054aad015b673586db9acc_5fc6ec90d5035e1042f79227_shamik_mukhopadhyay-p-500.webp",
      },
      {
        HEADING: "The idea of Preplaced is pretty simple & effective",
        TEXT: "Connect with experts from any company & prepare with them. Easy to know your skill gaps & get feedback.",
        NAME: "Anirban Mitra",
        COMPANY: "Microsoft",
        COMPANYPHOTO:
          "https://assets.website-files.com/5fe47ec3e084a85e759e361f/63054a7abb86c2a41a7872d3_60778d614f86e780918e16d6_microsoft-p-500.png",
        PHOTO:
          "https://assets.website-files.com/5fe47ec3e084a85e759e361f/63054a78db39fbf207ac1ab1_62515f894c9d7834f311d8b2_Anirban%20mitra.webp",
      },
    ],
    CHECKOUT: {
      TEXT_BOX: [
        "1 - 1 Customized Interview Preparation with a Qualified / Dedicated Mentor",
        "Preparation plan personalised for your goals",
        "Resources and tasks to prepare right and faster",
        "Continious 1-1 sessions for guidance and evaluation",
        "24 / 7 dedicated chat support with mentor",
      ],
    },
    EMPTYSTATE: {
      AreaOfImprovements: {
        GUIDANCE: [
          "Detailed Roadmap & plan",
          "Regular Syncups",
          "Detailed Evaluations & Feedback",
          "Explaining interview process",
          "Doubt solving",
        ],
        JOBSEARCH: ["Curated Resources", "Technical Mock Interview"],
        PRACTICE: [
          "Resume Building",
          "Job application assistance",
          "Profile building",
        ],
      },
    },
  },
  PlanningSession:{
    MENTOR: {
      SUB_TITLE:"Our team at Preplaced have carefully curated a planer doc that can be used to make the most out of the planning session to create a roadmap for your mentee.",
      CARDDETAILS:[
        {
          id:1,
          gif:OpenPlannerGif,
          buttonText:"Open Planner",
          plannerDocUrl: params["planner-doc-url"]
        },
        {
          id:2,
          gif:ShareScreenGif,
          buttonText:"Share Screen",
          cardText: "Share your screen"
        },
      ]
    },
    MENTEE:{
      SUB_TITLE:"Your mentor will be creating a plan using the planner doc.",
      CARDDETAILS:[
        {
          gif:OpenPlannerGif,
          buttonText:"Open Planner",
          plannerDocUrl: params["planner-doc-url"]
        },
      ]
    }
  }
};

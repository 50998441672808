export const ConfigsToSelectorMapper = (configApiData: any) => {
  
  //domain
  let mentorConfigData: any = {};

  //skill set
  let skillArrayList: any[] = [];
  configApiData.skills.forEach((eachSkill: any) => {
    let skill = {
      value: eachSkill,
      label: eachSkill,
    };
    skillArrayList.push(skill);
  });

  // removing duplicates here
  const result = skillArrayList.filter((thing, index, self) =>
  index === self.findIndex((t) => (
    t.value === thing.value && t.label === thing.label
  ))
  )
  mentorConfigData["skill_options"] = result;

  return mentorConfigData;
};

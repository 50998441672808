import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import firebase from 'firebase/compat/app'
import config from '../env.config'

var firebaseConfig = {
  apiKey: config.REACT_APP_FIREBASE_API_KEY,
  authDomain: config.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: config.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: config.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.REACT_APP_FIREBASE_MESSENGER_SENDER_ID,
  appId: config.REACT_APP_FIREBASE_APP_ID,
  measurementId: config.REACT_APP_FIREBASE_MEASUREMENT_ID,
}
  
const app = firebase.initializeApp(firebaseConfig)

// Remove this error: 
// const errorHandler = firebase.firestore().settings({ experimentalForceLongPolling: true })

const db = getFirestore(app)

export {db, app, firebase}
import RocketLaunch from 'assets/icons/rocket-launch.svg'
import TeamBuild from 'assets/icons/team-build.svg'
import CurvedPath from 'assets/icons/curved-path.svg'
import Target from 'assets/icons/target.svg'

const getPackageLogo = (packageName: string) => {
  if (packageName === "1:1 Planning") { //
    return CurvedPath;
  } else if (packageName === "1:1 Consultation") {
    return RocketLaunch;
  } else if (packageName === "1:1 Skill Enhancement") { //
    return TeamBuild;
  } else if (packageName === "1:1 Resume Review") {
    return RocketLaunch;
  } else if (packageName === "1:1 Mock Interview") {
    return Target;
  } else if (packageName === "1:1 Job Application Assistance") {
    return CurvedPath;
  } else if (packageName === "1:1 Career Guidance") {
    return RocketLaunch;
  } else if (packageName === "1:1 Interview Process Clarification") {
    return TeamBuild;
  } else {
    return RocketLaunch;
  }
}

export default getPackageLogo;
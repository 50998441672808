import React, { useState, useEffect } from "react";
import { CONTENT } from "CONSTANT/CONSTANT";
import PlanningCard from "./PlanningCard";
import Loader from "common/Loader/Loader";
import { useQuery } from "utils/hooks/useQuery";

const PlanningSession = () => {
  const params = useQuery();
  const ROLE = params && (params?.role)?.toUpperCase();
  const planningData = CONTENT.PlanningSession[ROLE || "MENTEE"];

  return (
    <>
        <div className="bg-black flex flex-col h-full justify-center items-center gap-9">
          <div className="space-y-2 max-w-3xl text-left w-full">
            <p className="text-blue-600 font-semibold text-[42px]">
              Welcome to the Planning Session
            </p>
            <p className="text-white text-md w-10/12">
            {planningData.SUB_TITLE}
            </p>
          </div>
          <div className="flex gap-x-14 relative max-w-3xl">
            {planningData.CARDDETAILS.map((item: any) => (
              <PlanningCard
                key={item.id}
                id={item.id}
                gifData={item.gif}
                buttonText={item.buttonText}
                cardText={item.cardText}
                plannerDocUrl={item.plannerDocUrl}
              />
            ))}
          </div>
        </div>
      
    </>
  );
};

export default PlanningSession;

import React, { useState } from 'react'
import MentorProfileConverBackground from "assets/images/MentorProfileConverBackground.png"
import MenteeProfileConverBackground from "assets/images/MenteeProfileConverBackground.png"
import { useAppSelector } from 'state/hooks'
import MenteeDetailsSection from './MenteeDetailsSection'
import MentorDetailsSection from './MentorDetailsSection'
import ArrowUpRight from "assets/icons/ArrowUpRight.svg";
import { newEventPropertyBuilder } from 'api/planning'
import { trackAnalytics } from 'api/analytics'
import PDFModal from 'common/PdfModal/PdfModal'

const LeftSide = () => {
  const menteeData = useAppSelector((state) => state.planner.menteeData);
  const mentorData = useAppSelector((state) => state.planner.mentorData);
  const ROLE = useAppSelector((state) => state.generalConfigs.join_role);
  const [resumeOpened, setResumeOpened] = useState(false);
  const resumeUrl = useAppSelector((state) => state.plannerInputAreas.resumeUrl);
  const menteeEmail = useAppSelector((state: any) => state.generalConfigs.menteeEmail);
  const mentorEmail = useAppSelector((state: any) => state.generalConfigs.mentorEmail);
  const initialTrialLaunchTime = useAppSelector((state) => state.generalConfigs.trial_doc_opened_time);

  const handleResumeOpen = () => {
    setResumeOpened(true);
    const resumeButtonEvent = newEventPropertyBuilder({
      mentee_email: menteeEmail,
      mentor_email: mentorEmail,
      user_email: mentorData.email,
      product_name: "Trial Doc",
      event_name: "Resume Viewed",
      extra_track_properties: {
        time_passed: Math.round(
          (new Date().getTime() - initialTrialLaunchTime) / (60 * 1000)
        ),
      },
    });
    trackAnalytics(resumeButtonEvent);
  };

  const handleResumeClose = () => {
    setResumeOpened(false);
    const resumeButtonEvent = newEventPropertyBuilder({
      mentee_email: menteeEmail,
      mentor_email: mentorEmail,
      user_email: mentorData.email,
      product_name: "Trial Doc",
      event_name: "Resume Closed",
      extra_track_properties: {
        time_passed: Math.round(
          (new Date().getTime() - initialTrialLaunchTime) / (60 * 1000)
        ),
      },
    });
    trackAnalytics(resumeButtonEvent);
  };

  return (
    <div className='p-10 h-[100vh] overflow-y-scroll no-scrollbar'>
      <div className='border border-[#222] rounded-xl'>
        <div className='relative h-[100px] rounded-t-xl bg-cover' style={{ backgroundImage: `url(${ROLE === "mentor" ? MenteeProfileConverBackground : MentorProfileConverBackground})` }} >
          <div className='absolute left-1/2 bottom-0 translate-y-1/2 -translate-x-1/2'>
            {ROLE === "mentor" ?
              <img
                src={menteeData?.photoUrl}
                alt="profile_photo.png"
                className="w-20 h-20 rounded-full"
                onError={(event: any) => {
                  event.target.src = `https://ui-avatars.com/api/?name=${mentorData?.name}&background=7f56d9&color=fff&length=2&bold=true`;
                  event.onerror = null;
                }}
              />
              :
              <img
                src={mentorData?.photoUrl}
                alt="profile_photo.png"
                className="w-20 h-20 rounded-full"
                onError={(event: any) => {
                  event.target.src = `https://ui-avatars.com/api/?name=${menteeData?.candidateName}&background=7f56d9&color=fff&length=2&bold=true`;
                  event.onerror = null;
                }}
              />
            }
          </div>
        </div>
        <div className='px-6'>
          <div className='flex flex-col items-center mt-9 pt-5 pb-6 border-b border-b-[#222]'>
            <p className='text-white text-xl leading-6 font-semibold mb-1.5 text-center'>
              {ROLE === "mentor" ? menteeData?.candidateName : mentorData?.name}
            </p>
            <div className='text-xs text-gray-400'>
              {ROLE === "mentor" ?
                <p className='w-full text-center'>
                  <span className='text-green-400'>Target: </span>
                  {menteeData?.targetDomains.join(", ")}&nbsp;at&nbsp;{menteeData?.targetCompanies.join(", ")}
                </p>
                :
                mentorData.currentDesignation && mentorData.currentCompany &&
                <span className='text-xs text-white/[0.8]'>{mentorData.currentDesignation} @{mentorData.currentCompany}</span>
              }
            </div>
            {ROLE === "mentor" &&
              <>
                <button disabled={!resumeUrl} className={`mt-5 flex items-center px-3 py-2.5 bg-[#222] rounded-lg border border-[#fff]/[0.05] ${resumeUrl ? "text-white" : "text-[#fff]/[0.3] cursor-not-allowed"}`} onClick={handleResumeOpen}>
                  <p className="font-medium text-[13px] leading-[18px]">View resume</p>
                </button>
                <PDFModal
                  pdfUrl={resumeUrl}
                  open={resumeOpened}
                  modalTitle={`${menteeData?.candidateName}'s Resume`}
                  handleCloseOutside={handleResumeClose}
                  handleClose={handleResumeClose}
                  className="w-[calc(100vw-30rem)] h-[calc(100vh-10rem)]"
                />
              </>
            }
          </div>
        </div>
        <div className='p-6 pt-8'>
          {ROLE === "mentor" ? <MenteeDetailsSection menteeData={menteeData} /> : <MentorDetailsSection mentorData={mentorData} />}
        </div>
      </div>
    </div>
  )
}

export default LeftSide
import React from 'react'

type Props = {
  bgcolor: string,
  progress: number,
  height: number,
}

const ProgressBar = ({bgcolor,progress,height}: Props) => {
  const Parentdiv = {
      height: height,
      backgroundColor: 'whitesmoke',
      borderRadius: 40,
    }
    
    const Childdiv = {
      height: '100%',
      width: `${progress}%`,
      backgroundColor: bgcolor,
     borderRadius:40,
    }
      
  return (
  <div style={Parentdiv}>
    <div style={Childdiv}>
    </div>
  </div>
  )
}

export default ProgressBar;
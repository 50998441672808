import useMainHook, { componentToBeRenderEnum } from "./use-main-hook";
import PostSyncupFeedbackMentor from "./task-section/post-syncup-feedback-mentor";
import NextMeetScreen from "./next-meet-screen/next-meet-screen";
import ThankYouScreen from "./thank-you-screen/thank-you-screen";

const PostSyncUpFeedbackContainer = () => {
  const {
    componentToBeRender,
    handleNextScreen,
    menteeDetails,
    mentorDetails,
    packageRecordId
  } = useMainHook();
  switch (componentToBeRender) {
    case componentToBeRenderEnum.SET_NEXT_MEET_DATE_SCREEN:
      return <NextMeetScreen menteeDetails={menteeDetails} mentorDetails={mentorDetails}/>;
    case componentToBeRenderEnum.THANK_YOU_SCREEN:
      return <ThankYouScreen menteeDetails={menteeDetails} mentorDetails={mentorDetails} />;
    case componentToBeRenderEnum.ADD_TASKS_SCREEN:
    default:
      if(packageRecordId){
          return <PostSyncupFeedbackMentor handleNextScreen={handleNextScreen} menteeDetails={menteeDetails} mentorDetails={mentorDetails} packageRecordId={packageRecordId}/>
      }else{
        return <PostSyncupFeedbackMentor handleNextScreen={handleNextScreen} menteeDetails={menteeDetails} mentorDetails={mentorDetails} />;
      }
  }
};

export default PostSyncUpFeedbackContainer;

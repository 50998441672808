import React from "react";
import Headphones from "assets/icons/Headphones.svg";
import MoneyBack from "assets/icons/MoneyBack.svg";
import Slack from "assets/icons/Slack.svg";

const MenteeSupport = () => {
  return (
    <div className="">
      <div>
        <p className="font-medium text-gray-400 xl:text-[32px] xl:leading-[36px] lg:text-[28px] lg:leading-[32px] md:text-[24px] md:leading-[28px] sm:text-[20px] sm:leading-[24px] text-[9px] text-center">And we make sure we are there for you. Always!</p>
      </div>
      <div className="flex sm:mt-9 mt-[10px] divide-x divide-gray-300 justify-center items-center">
        <div className="flex-[0.33] flex flex-col items-center">
          <img src={Headphones} alt="" className="md:w-[75px] md:h-[75px] sm:w-[50px] sm:h-[50px] w-[20px] h-[20px]" />
          <p className="text-center text-gray-800 font-semibold md:w-2/12 w-4/12 xl:text-[21px] xl:leading-[25px] sm:text-sm text-[7px]">24x7 Support</p>
        </div>
        <div className="flex-[0.33] flex flex-col items-center">
          <img src={MoneyBack} alt="" className="md:w-[75px] md:h-[75px] sm:w-[50px] sm:h-[50px] w-[20px] h-[20px]" />
          <p className="text-center text-gray-800 font-semibold md:w-4/12 w-5/12 xl:text-[21px] xl:leading-[25px] sm:text-sm text-[7px]">Money back Guarantee</p>
        </div>
        <div className="flex-[0.33] flex flex-col items-center">
          <img src={Slack} alt="" className="md:w-[75px] md:h-[75px] sm:w-[50px] sm:h-[50px] w-[20px] h-[20px]" />
          <p className="text-center text-gray-800 font-semibold md:w-4/12 w-5/12 xl:text-[21px] xl:leading-[25px] sm:text-sm text-[7px]">Dedicated Slack group</p>
        </div>
      </div>
    </div>
  );
};

export default MenteeSupport;

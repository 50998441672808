import { createSlice, PayloadAction, PayloadActionCreator } from '@reduxjs/toolkit'
import { RootState } from '../store'


let initialState = {
    // UI States
    currentCompany: '',
}

const plannerDocSlice2 = createSlice({
    name: 'plannerDoc2',

    initialState: {
        ...initialState,
    },
    
    reducers: {
        setCurrentCompany: (state: any, action:PayloadAction<any>) => {
            state.currentCompany = action.payload
        },
        // setPlannerDoc: (state: any, action:PayloadAction<any>) => {
        //     state.plannerDoc.plannerDoc = action.payload
        // },
    },
})

export const {
    // setCurrentCompany,
} = plannerDocSlice2.actions

export default plannerDocSlice2.reducer
export const selectPlannerDoc2 = (state: RootState) => state.plannerDoc2

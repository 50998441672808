import React from 'react'
import { useAppSelector } from 'state/hooks'
import MenteeSupport from './footer/MenteeSupport'
import Testimonials from './footer/MentorTestimonials'

import Navbar from './header/UpgradeNavbar'
import PlanOfAction from './plan-of-action/PlanOfAction'
import ReportAnalysis from './report-analysis/ReportAnalysis'
import Timeline from './timeline/Timeline'


type Props = {}

const QuotationContent = (props: Props) => {
  const interviewReadinessScore = useAppSelector(state => state.plannerInputAreas.interviewReadinessScore)
  return (
    <div>
      <Navbar />
      <div className='flex flex-col gap-32 max-w-[1366px] mx-auto'>
        <Timeline interviewReadiness={interviewReadinessScore} />
        <div id="report_analysis">
          <ReportAnalysis interviewReadinessScore={interviewReadinessScore} />
        </div>
        <div id="plan_of_action">
          <PlanOfAction />
        </div>
      </div>
      <div className='flex flex-col gap-20'>
        <Testimonials />
        <MenteeSupport />
        <div className='h-5'>
        </div>
      </div>
    </div>
  )
}

export default QuotationContent
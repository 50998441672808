export default function checkIfImageExists(url: any, callback: any) {
  const img = new Image();
  img.src = url;
  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };
    img.onerror = () => {
      callback(false);
      // console.clear()
    };
  }
}

import { analytics } from "../common/analytics";

export const sendAnalyticsToSegment = {
    track:(eventName:string,propertiesData:any={}) => {
        var params:any = Object.fromEntries(
            new URLSearchParams(window.location.search).entries()
          );
        try{
            var properties = {
                ...params,
                ...propertiesData,
                // listing_layout: +(localStorage.getItem("displayMentorCard") as any) || null,
            };
                analytics().track(eventName,properties)
                console.log("eventName: ", eventName, "\n properties: ", properties);
        }catch(error){
            console.error("Error In sendAnalyticsToSegment track",error);
        }
    },
    identify:(email:string,identities:any={}) => {
        try{
            analytics().identify(email,identities);
            console.log("email :",email, " identities :", identities);
        }catch(error){
            console.error("Error in sendAnalyticsToSegment identify",error);
        }
    }
}
import { Dialog, Transition } from '@headlessui/react'
// import { XIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'

interface Props {
  children?: any
  open?: boolean
  handleClose?: any
  modalTitle?: string
  handleCloseOutside?: any
  className?: string
  showTopStickyTitleBar?: boolean
  modalChildrensPadding?: string
}

export const Modal: React.FC<Props> = (props) => {
  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={props.handleCloseOutside}
        >
          <div className='flex justify-center h-screen items-center p-5'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter='ease-out duration-100'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div
                className={`${props.className} relative inline-block align-top bg-slate-50 rounded-lg w-[1024px] h-[calc(100vh-5rem)] text-left overflow-hidden shadow-xl transform transition-all`}
              >
                {props.showTopStickyTitleBar && (
                  <div
                    className={`flex items-center justify-between p-4 bg-slate-50 border-b sticky top-0`}
                  >
                    <Dialog.Title
                      as='h3'
                      className='text-xl font-serif leading-6 text-gray-700'
                    >
                      {props.modalTitle}
                    </Dialog.Title>
                    {props.handleClose && <div className=''>
                      {/* <XIcon
                        onClick={props.handleClose}
                        width={32}
                        height={32}
                        className='opacity-50 bg-slate-200 p-2 rounded-full cursor-pointer active:bg-slate-300 duration-300'
                      /> */}
                    </div>}
                  </div>
                )}

                <div className={props.modalChildrensPadding}>
                  {props.children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

Modal.defaultProps = {
  showTopStickyTitleBar: true,
  handleCloseOutside: () => null,
  modalChildrensPadding: 'p-4',
}

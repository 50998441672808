export class menteeData {
  constructor(
    candidateName: string,
    goals: any[],
    longTermMentorshipHelp: string,
    problemsForGoals: string,
    userCategory: string,
    currentCompany: string,
    currentRole: string,
    collegeDegree: string,
    collegeName: string,
    resumeUrl: string,
    id: string,
    expectedPreparationDuration: string,
    targetCompanies: string,
    targetDomains: string[],
    upcomingInterviewDate: string,
    experience: string,
    pain_points: string,
    target_roles: string,
    email: string,
    interviewReadinessScore: number,
    effortsProgress: string,
    graduatingIn: string,
    mentor_email: string,
    photoUrl: string,
    pastRole: string,
    pastCompany: string
  ) {
    this.candidateName = candidateName;
    this.goals = goals;
    this.longTermMentorshipHelp = longTermMentorshipHelp;
    this.problemsForGoals = problemsForGoals;
    this.userCategory = userCategory;
    this.currentCompany = currentCompany;
    this.currentRole = currentRole;
    this.collegeDegree = collegeDegree;
    this.collegeName = collegeName;
    this.collegeName = collegeName;
    this.resumeUrl = resumeUrl;
    this.id = id;
    this.expectedPreparationDuration = expectedPreparationDuration;
    this.targetCompanies = targetCompanies;
    this.targetDomains = targetDomains;
    this.upcomingInterviewDate = upcomingInterviewDate;
    this.experience = experience;
    this.painPoints = pain_points;
    this.targetRoles = target_roles;
    this.email = email;
    this.interviewReadinessMentee = interviewReadinessScore;
    this.effortsTakenByMentee = effortsProgress;
    this.graduatingIn = graduatingIn;
    this.mentorEmail = mentor_email;
    this.photoUrl = photoUrl;
    this.pastRole = pastRole;
    this.pastCompany = pastCompany;
  }

  id?: string;
  goals: any[];
  longTermMentorshipHelp: string;
  problemsForGoals: string;
  currentCompany?: string;
  currentRole?: string;
  resumeUrl?: any;
  userCategory?: string;
  collegeDegree?: string;
  collegeName?: string;
  upcomingInterviewDate?: string;
  targetCompanies?: string;
  targetDomains?: string[];
  expectedPreparationDuration?: string;
  experience?: string;
  painPoints?: string;
  targetRoles?: string;
  candidateName?: string;
  email?: string;
  interviewReadinessMentee?: number;
  effortsTakenByMentee?: string;
  graduatingIn?: string;
  mentorEmail?: string;
  photoUrl?: string;
  pastRole?: string;
  pastCompany?: string;
}

export class mentorData {
  constructor(
    id: string,
    name: string,
    ltmJson: any,
    email: string,
    photoUrl: string,
    domain: string,
    linkedInUrl: string,
    currentCompany: string,
    currentDesignation: string,
    pastCompanies: string,
    totalExperience: number,
    averageSessionRating: number,
    totalSessions: number,
    totalReviews: number,
    profileBio: string,
    skillSet: string[],
    languagePreference: string[],
    careerJourneyJson: any,
    publicProfileId: string,
    referralPreference: string[],
    project: string,
    teaching: string,
    sessionFrequency: number,
    followupFrequency: string,
    responsivenessOnPreplacedChat: string,
    adhocCalls: string,
    monthlyMentorshipPrice: number,
    longTermPitch: string,
    menteePersona: string[],
    menteeLimit: number,
    menteeAspirations: string[],
    expectationsFromMentees: string,
    monthlyTrialSettings: number,
    technicalSkillsJson: any,
    mentoringSkillsJson: any
  ) {
    this.id = id;
    this.name = name;
    this.ltmJson = ltmJson;
    this.email = email;
    this.photoUrl = photoUrl;
    this.domain = domain;
    this.linkedInUrl = linkedInUrl;
    this.currentCompany = currentCompany;
    this.currentDesignation = currentDesignation;
    this.pastCompanies = pastCompanies;
    this.totalExperience = totalExperience;
    this.averageSessionRating = averageSessionRating;
    this.totalSessions = totalSessions;
    this.totalReviews = totalReviews;
    this.profileBio = profileBio;
    this.skillSet = skillSet;
    this.languagePreference = languagePreference;
    this.careerJourneyJson = careerJourneyJson;
    this.publicProfileId = publicProfileId;
    this.referralPreference = referralPreference;
    this.project = project;
    this.teaching = teaching;
    this.sessionFrequency = sessionFrequency;
    this.followupFrequency = followupFrequency;
    this.responsivenessOnPreplacedChat = responsivenessOnPreplacedChat;
    this.adhocCalls = adhocCalls;
    this.monthlyMentorshipPrice = monthlyMentorshipPrice;
    this.longTermPitch = longTermPitch;
    this.menteePersona = menteePersona;
    this.menteeLimit = menteeLimit;
    this.menteeAspirations = menteeAspirations;
    this.expectationsFromMentees = expectationsFromMentees;
    this.monthlyTrialSettings = monthlyTrialSettings;
    this.technicalSkillsJson = technicalSkillsJson;
    this.mentoringSkillsJson = mentoringSkillsJson;
  }
  id?: string;
  name?: string;
  ltmJson?: any;
  email?: string;
  photoUrl?: string;
  domain?: string;
  linkedInUrl?: string;
  currentCompany?: string;
  currentDesignation?: string;
  pastCompanies?: string;
  totalExperience?: number;
  averageSessionRating?: number;
  totalSessions?: number;
  totalReviews?: number;
  profileBio?: string;
  skillSet?: string[];
  languagePreference?: string[];
  careerJourneyJson?: any;
  publicProfileId?: string;
  referralPreference?: string[];
  project?: string;
  teaching?: string;
  sessionFrequency?: number;
  followupFrequency: string;
  responsivenessOnPreplacedChat: string;
  adhocCalls?: string;
  monthlyMentorshipPrice: number;
  longTermPitch: string;
  menteePersona: string[];
  menteeLimit: number;
  menteeAspirations: string[];
  expectationsFromMentees: string;
  monthlyTrialSettings: number;
  technicalSkillsJson: any;
  mentoringSkillsJson: any;
}

export class servicesData {
  constructor(
    serviceName: string,
    description: string,
    callDuration: string,
    guidelinesUrl: string,
    whyDescription: string,
    whyPlaceholder: string,
    numberOfSessionsRecommended: number,
    totalPricing: number,
    mentorPayoutPricing: number
  ) {
    this.serviceName = serviceName;
    this.description = description;
    this.callDuration = callDuration;
    this.guidelinesUrl = guidelinesUrl;
    this.whyDescription = whyDescription;
    this.whyPlaceholder = whyPlaceholder;
    this.numberOfSessionsRecommended = numberOfSessionsRecommended;
    this.totalPricing = totalPricing;
    this.mentorPayoutPricing = mentorPayoutPricing;
  }
  serviceName?: string;
  description?: string;
  callDuration?: string;
  guidelinesUrl?: string;
  whyDescription?: string;
  whyPlaceholder?: string;
  numberOfSessionsRecommended?: number;
  totalPricing?: number;
  mentorPayoutPricing?: number;
}

import React from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import ResizeOverlay from "common/ResizeOverlay/ResizeOverlay";
import MentorView from "./MentorView/MentorView";
import MenteeView from "./MenteeView/MenteeView";
import { setTimelineDropDownState } from "state/timeline/timelineReducer";
import { TrialV2Container } from "views/TrialV2Container";
import CommonView from "./common-view/CommonView";

const NewSteps = () => {
  const dispatch = useAppDispatch();
  // const ROLE = useAppSelector((state) => state.generalConfigs.join_role);

  return (
    <TrialV2Container>
      <ResizeOverlay>
        <main
          className="min-h-screen bg-[#161616]"
          onClick={() => {
            dispatch(setTimelineDropDownState(false));
          }}
        >
          <CommonView />
          {/* {ROLE === "mentor" ? <MentorView /> : <MenteeView />} */}
        </main>
      </ResizeOverlay>
    </TrialV2Container>
  );
};

export default NewSteps;

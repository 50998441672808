import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, updateDoc } from "firebase/firestore";
import { CONTENT } from "../CONSTANT/CONSTANT";
import { CONTENT_V2 } from "../CONSTANT/CONSTANT_V2";
import { db } from "../lib/firebase";
import { useAppSelector } from "../state/hooks";
import { ConfigsToSelectorMapper } from "../utils/mapper/ConfigsToSelectorMapper";
import {
  handleGenericApiGet,
  handlePost,
  handleDashboardGet,
  handleDashboardPost,
  handleResumePost,
  handleMenteeDashboardPost,
} from "./axios";
import config from "../env.config";
import * as Sentry from "@sentry/react";
import { analytics } from "../common/analytics";
import { PlanInfoMentorInfoMapper } from "../utils/mapper/planInfoMentorInfoMapper";
import { PricingMapper } from "../utils/mapper/pricingMapper";
import platform from "platform";
import { PlanInfoMapperV2 } from "utils/mapper/PlanInfoMapperV2";
import {
  planInfoMenteeProfileDataMapper,
  planInfoMentorProfileDataMapper,
  servicesConfigToOptedInServicesMapper,
} from "utils/mapper/PlanInfoMapper";

export const getPlanningData = async (candidateId: string) => {
  // get meeting info from new operations airtable
  let query = `query MyQuery {
    candidates(id: "${candidateId}") {
      id
      name
      userCategory
      currentCompany
      currentRole
      collegeName
      resume
      expectedPreparationDuration
      targetCompanies
      upcomingInterview
      workExperience
      painPoints
      targetRole
      email
      interviewReadinessScore
      effortsProgress
      graduatingIn
      currentMentor {
        email
      }
      packages {
        id
      }
    }
  }`;
  // making a postCall to get meeting Data from new opertaions table
  const getNewMeetingInfoResponse = await getDataUsingBaseQLQuery({
    query: query,
  });
  return {};
};

export const getPlanningDataForMentee = async (requestParam: any) => {
  let response = await handleDashboardGet(
    "candidates/public",
    requestParam
  );
  response["data"] = planInfoMenteeProfileDataMapper(response["data"]);
  return response["data"];
};

export const postMenteeData =async (requestBody: any, requestParam: any) => {
  const response = await handleMenteeDashboardPost("/candidates/public/profile", requestBody, requestParam)
  return response;
}

export const getPlanningDataForMentor = async (requestParam: any) => {
  let response = await handleDashboardGet(
    "/mentors/public/get-profile-by-id",
    requestParam
  );
  // console.log(response['data'])
  response["data"] = planInfoMentorProfileDataMapper(response["data"]);
  return response["data"];
};

export const getMentorServicesPublic = async (requestParam: any) => {
  let allServicesConfigList = await handleDashboardGet(
    "/services/get-configurations",
    requestParam
  );
  let optedInServicesList = await handleDashboardGet(
    "/services/get-public-by-email",
    requestParam
  );
  let mentorServicesData = servicesConfigToOptedInServicesMapper(
    allServicesConfigList["data"],
    optedInServicesList["data"]
  );
  return mentorServicesData;
};

export const getSessionsPublicData = async (requestParam: any) => {
  let response = await handleDashboardGet(
    "/sessions/public/get-trial-doc",
    requestParam
  );
  return response["data"];
};
export const getPlanPublicData = async (requestParam: any) => {
  let response = await handleDashboardGet("/plan/get-details", requestParam);
  return response["data"];
};

export const createTrialDocNotification = async (requesdBody: any) => {
  let response = await handleDashboardPost(
    "active-notifications/public/create-notification",
    requesdBody
  );
  console.log(response);
};

export const trialDocCompleted = async (requesdBody: any) => {
  let response = await handleDashboardPost(
    "sessions/public/doc-complete",
    requesdBody
  );
  console.log(response);
};

export const getPlanningDataV2 = async (candidateId: string) => {
  // get meeting info from new operations airtable
  let query = `query MyQuery {
    candidates(id: "${candidateId}") {
      id
      name
      goals
      longTermMentorshipHelp
      problemsForGoals
      userCategory
      currentCompany
      currentRole
      collegeName
      resume
      expectedPreparationDuration
      targetCompanies
      upcomingInterview
      workExperience
      painPoints
      targetRole
      email
      interviewReadinessScore
      effortsProgress
      graduatingIn
      currentMentor {
        email
      }
      packages {
        id
      }
    }
  }`;
  // making a postCall to get meeting Data from new opertaions table
  const getNewMeetingInfoResponse = await getDataUsingBaseQLQuery({
    query: query,
  });
  return PlanInfoMapperV2(getNewMeetingInfoResponse);
};

export const getPlanInfoMentorInfoMapper = async (candidateId: string) => {
  // get meeting info from new operations airtable
  let query = `query MyQuery {
        candidates(id: "${candidateId}") {
          preferredMentorExperienceFromOrders
          currentMentor {
            name
            email
            currentCompany
            currentDesignation
            domain
            skillset
            publicProfileLink
            photoUrl
            ltmJson
            sessions {
              frFeedbackSubmittedByTheMentee {
                sessionType
                mentorRating
                addSomeKindWordsForTheMentorItHelpsThemBuildThierProfileOnThePreplacedPlatformAndEncouragesThemToContinueDeliveringQualityMentorshipInTheLongerRun
              }
              candidate {
                id
                name
                photoUrl
              }
            }
          }
        }
    }`;
  // making a postCall to get meeting Data from new opertaions table
  const mentorInfoResponse = await getDataUsingBaseQLQuery({
    query: query,
  });
  return PlanInfoMentorInfoMapper(mentorInfoResponse);
};

export const getPricingInfo = async (
  preferredMentorExperience: string,
  targetRole: string,
  preparationDuration: string,
  targetCompanies: string[],
  upcomingInterview: string
) => {
  const pricingResponse = await handleGenericApiGet(
    config.REACT_APP_BACKEND_API_GET_PRICE
  );
  return PricingMapper(
    pricingResponse.data,
    preferredMentorExperience,
    targetRole,
    preparationDuration,
    targetCompanies,
    upcomingInterview
  );
};

const getDataUsingBaseQLQuery = async ({ query }: any) => {
  let reqBodyFormatted = query;
  let reqBody = reqBodyFormatted.replace(/(\r\n|\n|\r)/gm, "").trim();
  const response = await handlePost(
    config.REACT_APP_GET_USER_DATA_FROM_NEW_OPERATIONS,
    reqBody
  );
  return response;
};

const AssignMentorValidityToken = () => {
  localStorage.setItem("mentorValidityToken", "true");
};

export const updateFirestore = async (
  updatePath: string,
  data: any,
  forced: boolean = false
) => {
  // let docId = query.get('doc_id') ? query.get('doc_id') : ''
  AssignMentorValidityToken();
  const pathName = window.location.pathname.split("/");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ROLE = urlParams.get("role");
  const isRecorder = urlParams.get("recorder");

  // only mentor and forced=true and non recorders 3rd parameter can update firestore values
  // if(isRecorder === "false"){
  //   if(ROLE === "mentor" || forced){
  //     let sessionId: any = pathName[pathName.length - 1];
  //     let dataWithToUpdate: any = {}
  //     dataWithToUpdate[updatePath] = data
  //     const docRef = doc(db, 'plan', sessionId)
  //     await updateDoc(docRef, dataWithToUpdate).then(response => {
  //       return true
  //     }).catch((error) => {
  //       // Sentry.captureException(error);
  //       console.log("Unable to update planner doc")
  //       return false
  //     })
  //   }
  // }
  if (isRecorder === "false") {
    if (ROLE === "mentor" || ROLE === "mentee" || forced) {
      let sessionId: any = pathName[pathName.length - 1];
      let dataWithToUpdate: any = {};
      dataWithToUpdate[updatePath] = data;
      const docRef = doc(db, "plan", sessionId);
      await updateDoc(docRef, dataWithToUpdate)
        .then((response) => {
          return true;
        })
        .catch((error) => {
          // Sentry.captureException(error);
          console.log("Unable to update planner doc");
          return false;
        });
    }
  }
};

export const removeErrorInAllSections = () => {
  setTimeout(() => {
    updateFirestore("config.errorInSectionId", 0);
  }, 500);
};

export const fetchConfigurations = createAsyncThunk(
  "plannerDoc/fetchConfigurations",
  async (requestParam: any) => {
    let response = await handleGenericApiGet(
      config.REACT_APP_DASHBOARD_URL,
      requestParam
    );
    response["data"] = ConfigsToSelectorMapper(response["data"]);
    return response;
  }
);

export const GetNewConstantData = (path: string) => {
  try {
    // const ROLE = useAppSelector((state: any) => state.generalConfigs.join_role)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ROLE = urlParams.get("role");
    let DATA = CONTENT_V2;
    if (ROLE === "mentor") DATA = DATA["MENTOR"];
    else DATA = DATA["MENTEE"];
    path.split(".").forEach((node) => {
      DATA = DATA[node];
    });
    return DATA;
  } catch (error) {
    Sentry.captureException(error);
    // console.error("Please provide a valid path to get constant file data\n", error);
    return "";
  }
};

export const GetConstantData = (path: string) => {
  try {
    const ROLE = useAppSelector((state: any) => state.generalConfigs.join_role);
    let DATA = CONTENT;
    if (ROLE === "mentee") DATA = DATA["MENTEE"];
    else if (ROLE === "mentor") DATA = DATA["MENTOR"];
    path.split("/").forEach((node) => {
      DATA = DATA[node];
    });
    return DATA;
  } catch (error) {
    Sentry.captureException(error);
    console.error(
      "Please provide a valid path to get constant file data\n",
      error
    );
  }
};

export const getLocationInfo = async () => {
  const locationResponse = await handleGenericApiGet("https://ipinfo.io");
  return locationResponse;
};

export const SegmentAnalyticsTrack = async (analyticsName: string) => {
  const menteeData = useAppSelector(
    (state: any) => state.plannerDoc.menteeData
  );
  const ROLE = useAppSelector((state: any) => state.generalConfigs.join_role);
  const initialTrialLaunchTime = useAppSelector(
    (state) => state.generalConfigs.trial_doc_opened_time
  );

  await analytics().track(analyticsName, {
    mentee_email: menteeData.email,
    url: window.location.href,
    role: ROLE,
    time_passed: Math.round(
      (new Date().getTime() - initialTrialLaunchTime) / (60 * 1000)
    ),
  });
};

export const eventAnalytics = (trackDetails: any, productName: string) => {
  try {
    let trackData: any = {
      mentee_email: trackDetails.email,
      browser_name: platform.name,
      browser_version: platform.version,
      os_architecture: platform.os?.architecture,
      os_family: platform.os?.family,
      os_version: platform.os?.version,
      engine_name: platform.layout,
      device: platform.product,
      product_name: productName,
      url: window.location.href,
      mentor_email: trackDetails.mentor_email || "",
      package_id: trackDetails.package_id,
    };
    const params = new URLSearchParams(window.location.search);
    params.forEach((value, key) => {
      trackData[key] = value;
    });
    return trackData;
  } catch (error) {
    console.error(error);
  }
};

export const commonAnalyticsProperties = () => {
  const properties = {};
  return properties;
};

export const newEventPropertyBuilder = ({
  mentee_email,
  mentor_email,
  product_name,
  event_name,
  user_email,
  track_details,
  extra_track_properties,
}: any) => {
  try {
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    );
    const pathName = window.location.pathname.split("/");
    const sessionId: any = pathName[pathName.length - 1];
    const event_builder = {
      user_id: user_email,
      event: event_name,
      properties: {
        mentee_email: mentee_email,
        mentor_email: mentor_email,
        product_name: product_name,
        session_id: sessionId,
        ...track_details,
        ...params,
        ...extra_track_properties,
        browser_name: platform.name,
        browser_version: platform.version,
        os_architecture: platform.os?.architecture,
        os_family: platform.os?.family,
        os_version: platform.os?.version,
        engine_name: platform.layout,
        device: platform.product,
        url: window.location.href,
      },
    };
    return event_builder;
  } catch (error) {
    console.error("Error in eventPropertyBuilder", error);
  }
};

export const eventPropertyBuilder = ({
  track_details,
  product_name,
  role,
  event_name,
  extra_track_properties,
}: any) => {
  try {
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    );
    const event_builder = {
      user_id:
        role === "mentor" ? track_details.mentorEmail : track_details.email,
      event: event_name,
      properties: {
        mentee_email: track_details.email,
        browser_name: platform.name,
        browser_version: platform.version,
        os_architecture: platform.os?.architecture,
        os_family: platform.os?.family,
        os_version: platform.os?.version,
        engine_name: platform.layout,
        device: platform.product,
        product_name: product_name,
        url: window.location.href,
        mentor_email: track_details.mentorEmail || "",
        package_id: track_details.packageId,
        planner_id: track_details.id,
        ...params,
        ...extra_track_properties,
      },
    };
    return event_builder;
  } catch (error) {
    console.error("Error in eventPropertyBuilder", error);
  }
};
export function getUniqueSectionId(stepNumber: number, sectionNumber: number) {
  // Cantor Pairing Function
  // sectionIds = {(stepNumber,sectionNumber) : where stepNumber and sectionNumber belongs to Z+}
  return (
    0.5 * (stepNumber + sectionNumber) * (stepNumber + sectionNumber + 1) +
    sectionNumber
  );
}

export function decryptSectionId(sectionId: number) {
  // Invert Cantor Pairing Function
  let t = Math.floor((-1 + Math.sqrt(1 + 8 * sectionId)) / 2);
  let x = (t * (t + 3)) / 2 - sectionId;
  let y = sectionId - (t * (t + 1)) / 2;
  return [x, y];
}

export const uploadMedia = async (
  url: string,
  formData: FormData,
  filePath: any
) => {
  const uploadResponse = await handleResumePost(url, formData, {
    filepath: filePath,
  })
  return uploadResponse
}
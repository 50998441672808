import { baseApi } from "services/base-api";
import { endPoints } from "services/end-points";

/* -------------------------------------------------------------------------- */
/*                                 API Calling                                */
/* -------------------------------------------------------------------------- */
export type sendAnalyticsThroughBackendRqstType = {
  user_id: string;
  event: string;
  properties: {
    task?: {
      name: string;
    };
    mentor: {
      name: any;
      email: any;
      id: any;
    };
    mentee: {
      name: any;
      email: any;
      id: any;
    };
    product?: string;
    session_id?: string | undefined;
    url?: any;
    referrer?: string;
  };
};

export const sendAnalyticsThroughBackend = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    postAnalytics: builder.mutation<ResponseType, sendAnalyticsThroughBackendRqstType>({
      query: (requestBody) => ({
        url: endPoints?.analytics_track,
        method: "POST",
        body: requestBody,
      }),
    }),
  }),
});

export const { usePostAnalyticsMutation } = sendAnalyticsThroughBackend;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateProps {
  timelineState: boolean;
}

const initialState: initialStateProps = {
  timelineState: false,
};

const timelineSlice = createSlice({
  name: "timelineSelector",
  initialState,
  reducers: {
    setTimelineDropDownState: (state: any, action: PayloadAction<any>) => {
      state.timelineState = action.payload;
    },
  },
});

export const { setTimelineDropDownState } = timelineSlice.actions;
export default timelineSlice.reducer;
import Header from "../header/header";

type ThankYouScreenType = {
  menteeDetails: any;
  mentorDetails: any;
};

const ThankYouScreen = ({
  menteeDetails,
  mentorDetails,
}: ThankYouScreenType) => {
  return (
    <>
      <div className="w-full h-full pb-6 bg-white rounded-xl flex-col justify-start items-start gap-6 inline-flex shadow-sm">
      <Header menteeDetails={menteeDetails} mentorDetails={mentorDetails}/>
        <div className="flex-col justify-start items-end gap-6 flex">
          <div className="px-6 flex-col justify-start items-start gap-8 flex">
            <div className="flex-col justify-start items-start gap-3 flex">
              <div className="h-[68px] flex-col justify-start items-start gap-4 flex">
                <div className="self-stretch h-[68px] flex-col justify-start items-start gap-2 flex">
                  <div className="self-stretch text-slate-700 text-base font-semibold leading-normal">
                    Thank you for completing the process 🎉
                  </div>
                  <div className="self-stretch text-gray-500 text-xs font-normal leading-[18px]">
                    You have taken out the time to help your mentee prepare
                    better for the next session. Thanks for being a dedicated
                    mentor :)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYouScreen;

import { sendAnalyticsToSegment } from "api/analyticsV2";
import { handleDashboardGet } from "api/axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export enum componentToBeRenderEnum {
  ADD_TASKS_SCREEN = "Add Tasks",
  SET_NEXT_MEET_DATE_SCREEN = "Set Next Meet Date",
  THANK_YOU_SCREEN = "Thank You Screen",
}

export enum QueryParams {
  MENTOR_RECORD_ID = "mentorRecordId",
  MENTEE_RECORD_ID = "menteeRecordId",
  IS_RECURRING = "isRecurring",
  PACKAGE_RECORD_ID = "packageRecordId",
}

const useMainHook = () => {
  const [componentToBeRender, setComponentToBeRender] =
    useState<componentToBeRenderEnum>(componentToBeRenderEnum.ADD_TASKS_SCREEN);
  let [searchParams] = useSearchParams();
  const [menteeDetails, setMenteeDetails] = useState(null);
  const [mentorDetails, setMentorDetails] = useState(null);
  const packageRecordId = searchParams.get(QueryParams.PACKAGE_RECORD_ID);

  const screenStack = [
    componentToBeRenderEnum.ADD_TASKS_SCREEN,
    componentToBeRenderEnum.SET_NEXT_MEET_DATE_SCREEN,
    componentToBeRenderEnum.THANK_YOU_SCREEN,
  ];

  const getMenteeDetailsApi = async () => {
    const dataForApi = {
      url: "candidates/public",
      requestParams: {
        ids: searchParams.get(QueryParams.MENTEE_RECORD_ID),
      },
    };
    const response = await handleDashboardGet(
      dataForApi.url,
      dataForApi.requestParams
    );
    return response?.data[0];
  };

  const getMentorDetailsApi = async () => {
    const dataForApi = {
      url: "mentors/public/get-profile-by-id",
      requestParams: {
        id: searchParams.get(QueryParams.MENTOR_RECORD_ID),
      },
    };
    const response = await handleDashboardGet(
      dataForApi.url,
      dataForApi.requestParams
    );
    return response?.data;
  };

  const getMenteeAndMentorDetails = async () => {
    const menteeDetailsProcess = getMenteeDetailsApi();
    const mentorDetailsProcess = getMentorDetailsApi();

    const menteeDetails = await menteeDetailsProcess;
    const mentorDetails = await mentorDetailsProcess;

    //Identify for Events;
    sendAnalyticsToSegment.identify(mentorDetails?.email);
    sendAnalyticsToSegment.track("Page Visited");

    setMenteeDetails(menteeDetails);
    setMentorDetails(mentorDetails);
  };

  useEffect(() => {
    getMenteeAndMentorDetails();
  }, []);

  const handleNextScreen = () => {
    const currentScreenIndex = screenStack.findIndex(
      (item) => item === componentToBeRender
    );
    let nextScreen;
    if (searchParams.get(QueryParams.IS_RECURRING) === "true") {
      nextScreen = screenStack[screenStack.length - 1];
    } else {
      nextScreen =
        currentScreenIndex === screenStack.length - 1
          ? screenStack[0]
          : screenStack[currentScreenIndex + 1];
    }
    setComponentToBeRender(nextScreen);
  };

  return {
    componentToBeRender,
    handleNextScreen,
    menteeDetails,
    mentorDetails,
    packageRecordId
  };
};

export default useMainHook;

import CustomImage from "common/custom-image/custom-image";
import { useSearchParams } from "react-router-dom";

type HeaderType = {
  menteeDetails: any;
  mentorDetails: any;
};
const Header = ({ menteeDetails, mentorDetails }: HeaderType) => {
  return (
    <div className="self-stretch w-full px-8 py-5 bg-[#ECFDF3] border-b border-emerald-200 flex-col justify-start items-center gap-3 flex bg-add-tasks">
      <div className="w-16 h-10 relative">
        <CustomImage
          photoUrl={menteeDetails?.photoUrl}
          name={menteeDetails?.name}
          className="w-10 h-10 left-[24px] top-0 absolute rounded-full"
        />
        <CustomImage
          photoUrl={mentorDetails?.photoUrl}
          name={mentorDetails?.name}
          className="w-10 h-10 left-0 top-0 absolute rounded-full"
        />
      </div>
      <div className="self-stretch h-11 flex-col justify-start items-center gap-1.5 flex">
        <div className="self-stretch text-center text-slate-700 text-xs font-semibold">
          Hope you had a great session!
        </div>
        <div className="self-stretch text-center text-slate-700 text-xl font-bold">
          Next, let your mentee know...
        </div>
      </div>
    </div>
  );
};

export default Header;

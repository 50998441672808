// Plan 1 is always IPP

import React, { useRef } from 'react'
import { useAppSelector } from 'state/hooks'
import UserCheck from 'assets/icons/UserCheck.svg'
import GreenTick from 'assets/icons/GreenTick.svg'
import Gem from 'assets/icons/Gem.svg'
import { newEventPropertyBuilder, updateFirestore } from 'api/planning'
import config from "env.config";
import { sendAnalyticsToSegment } from 'api/analyticsV2'
import { trackAnalytics } from 'api/analytics'

type Props = {
  className?: string
  showTitleBar?: boolean
  topRounded?: boolean
}

const Plan1 = (props: Props) => {
  const IppSession = useAppSelector(state => state.plannerInputAreas.IppPackage)
  const mentorData = useAppSelector(state => state.planner.mentorData)
  const menteeData = useAppSelector((state: any) => state.planner.menteeData)
  const packageRecommended = useAppSelector(state => state.plannerInputAreas.packageRecommended)
  const initialUpgradeLaunchTime = useAppSelector(state => state.generalConfigs.trial_upgrade_opened_time)
  const menteeEmail = useAppSelector(state => state.generalConfigs.menteeEmail)
  const mentorEmail = useAppSelector(state => state.generalConfigs.mentorEmail)
  const pathName = window.location.pathname.split("/");
  const sessionId: any = pathName[pathName.length - 1];
  return (
    <div className={props.className}>
      {props.showTitleBar && <div className='flex justify-between text-sm mb-2'>
        <p className='text-[#667085]'>Package recommended:</p>
        <p className='text-[#667085] md:block hidden'>Total Sessions: {IppSession.sessionPerWeek * IppSession.timeRequired * 4} | Time: {IppSession.timeRequired} Months | Price: {IppSession.price}</p>
      </div>}
      <div className={`bg-white ${props.topRounded && "rounded-t-lg"} rounded-b-lg shadow-lg py-6 px-4 md:px-12`}>
        <div className='flex gap-6'>
          <img src={UserCheck} alt="" className='hidden md:block w-16 h-16 p-4 border-[1px] rounded-lg' />
          <div className='w-full flex flex-col gap-8'>
            <div className='flex'>
              <div className='grow'>
                <div className='flex gap-2 items-center'>
                  <img src={UserCheck} alt="" className='block md:hidden w-10 h-10 p-2 border-[1px] rounded-lg' />
                  <h1 className='font-bold text-lg sm:text-xl md:text-2xl'>Interview Preparation Program</h1>
                </div>
                <p className='pt-2 max-w-4xl text-sm sm:text-base'>IPP is a long-term mentorship program focused on helping you achieve your long-term goals i.e. getting placed in your target company. The mentor will be providing you with the guidance and practice needed for you to become interview ready and then help you get placed.</p>
              </div>
              <h1 className='font-bold text-2xl whitespace-nowrap hidden md:block'>Rs {IppSession.price}<span className='text-lg'>{IppSession.timeRequired > 1 && "/month"}</span></h1>
            </div>
            <div className='bg-[#e0eaff] flex flex-col gap-2 px-6 py-4 rounded-lg font-medium text-sm sm:text-base'>
              <div className='flex gap-2'>
                <img src={GreenTick} alt="" />
                <p className='text-[#344054]'>
                  The mentor recommends you
                  <span className='text-[#0029F5]'> {IppSession.sessionPerWeek} {IppSession.sessionPerWeek>1? "sessions/week" : "session/week"}</span>
                </p>
              </div>
              <hr className='border-[1px] border-[#d0d5dd] max-w-xl' />
              <div className='flex gap-2'>
                <img src={GreenTick} alt="" />
                <p className='text-[#344054]'>
                  The mentor recommends you dedicate
                  <span className='text-[#0029F5]'> {IppSession.hoursPerDay} {IppSession.hoursPerDay>1? "hours/day" : "hour/day"} </span>
                  for your interview preparation ⏱
                </p>
              </div>
              <hr className='border-[1px] border-[#d0d5dd] max-w-xl' />
              <div className='flex gap-2'>
                <img src={GreenTick} alt="" />
                <p className='text-[#344054]'>
                  The mentor plans to make you interview ready in
                  <span className=' text-[#0029F5]'> {IppSession.timeRequired} {IppSession.timeRequired>1? "months" : "month"}</span>
                  🚀
                </p>
              </div>
            </div>
            <div className='flex flex-col gap-3 text-sm sm:text-base font-semibold py-6 text-[#344054]'>
              <p>Along With:</p>
              <p>👩‍💻   Weekly sync-up sessions to track and evaluate your progress & unblock you when needed </p>
              <hr />
              <p>🙌   Curated resources that helps prepare right and faster </p>
              <hr />
              <p>💬   A dedicated private chat group with the mentor for unlimited doubt/query solving</p>
              <hr />
              <p>🔥   Detailed weekly skills gap analysis and feedback reports </p>
            </div>
            <div className='text-xs sm:text-base grid md:grid-cols-2 gap-4 bg-[#f2f4f7] p-4 rounded-lg'>
              <div className='flex gap-2'><img src={Gem} alt="" /><p className='text-[#344054] font-medium whitespace-nowrap'>Dedicated support manager</p></div>
              <div className='flex gap-2'><img src={Gem} alt="" /><p className='text-[#344054] font-medium whitespace-nowrap'>Access to our community</p></div>
              <div className='flex gap-2'><img src={Gem} alt="" /><p className='text-[#344054] font-medium whitespace-nowrap'>Dedicated mentor</p></div>
              <div className='flex gap-2'><img src={Gem} alt="" /><p className='text-[#344054] font-medium whitespace-nowrap'>Personalised preparation</p></div>
            </div>
            <div className='md:hidden flex justify-between'>
              <h3 className='text-xl'>Total</h3>
              <div>
                <h1 className='text-3xl font-bold text-right'>Rs {IppSession.price}<span className='text-xl'>{IppSession.timeRequired > 1 && "/month"}</span></h1>
                <p className='text-[#667085]'>({IppSession.sessionPerWeek} sessions/week)</p>
              </div>
            </div>
          </div>
        </div>
        <a
          className='flex justify-center text-xl bg-[#03020a] text-white w-full p-3 mt-6 hover:shadow-xl rounded-lg'
          href = {`${config.REACT_APP_PREPLACED_APP_URL}/profile/${mentorData.publicProfileId}?session_id=${sessionId}&c=true`}
          onClick={async () => {
            await updateFirestore("selectedPlanFromUpgrade", "IPP", true);
            const buyNowClickedEventBuilder = newEventPropertyBuilder({
              mentee_email:menteeEmail,
              mentor_email:mentorEmail,
              user_email:menteeData?.email,
              product_name: "Upgrade Report",
              event_name: "Buy Now Clicked",
              track_details:{
                plan: packageRecommended,
                plan_price: IppSession.price*(IppSession.timeRequired),
              },
              extra_track_properties: {
                time_passed: Math.round(
                  (new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000)
                ),
              },
            });
            trackAnalytics(buyNowClickedEventBuilder)
            // sendAnalyticsToSegment.w("Buy Now Clicked", {
            //   plan: packageRecommended,
            //   plan_price: IppSession.price * (IppSession.timeRequired),
            //   time_passed: Math.round((new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000))
            // })
            // window.open();
          }}
        >
          Start Plan
        </a>
      </div>
    </div>
  )
}

export default Plan1
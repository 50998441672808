import React, { useState, useEffect } from "react";
import checkIfImageExists from "../ImageChecker";
import Tippy from "@tippyjs/react";

const MentorWorkedAtCard = ({ companyLogoUrl, companyName }: any) => {
  const [clearBit, setClearBit] = useState(false);
  const [clearSplitBit, setClearSplitBit] = useState(false);
  const [clearSplitBitTwo, setClearSplitBitTwo] = useState(false);
  const [imageAvailable, setImageAvailable] = useState(false);
  const [color,setColor] = useState('');

  useEffect(() => {
    checkIfImageExists(companyLogoUrl, (exists: any) => {
      if (exists) {
        setImageAvailable(true);
        setClearBit(false);
      } else {
        checkIfImageExists(
          `https://logo.clearbit.com/${companyName
            .trim()
            .toLocaleLowerCase()}.com`,
          (exists: any) => {
            if (exists) {
              setImageAvailable(true);
              setClearBit(true);
            } else {
              checkIfImageExists(
                `https://logo.clearbit.com/${companyName
                  .split(" ")[0]
                  .trim()
                  .toLocaleLowerCase()}.com`,
                (exists: any) => {
                  if (exists) {
                    setImageAvailable(true);
                    setClearBit(false);
                    setClearSplitBit(true);
                  } else {
                    checkIfImageExists(
                      `https://logo.clearbit.com/${companyName
                        .split("-")[0]
                        .trim()
                        .toLocaleLowerCase()}.com`,
                      (exists: any) => {
                        if (exists) {
                          setImageAvailable(true);
                          setClearBit(false);
                          setClearSplitBitTwo(true);
                        } else {
                          setImageAvailable(false);
                          setClearBit(false);
                        }
                      }
                    );
                  }
                }
              );
            }
          }
        );
      }
    });
    setColor(randomColorForCompany());
  }, [companyLogoUrl, companyName]);

  const randomColorForCompany = () => {
    const color = [
      {
        bg: "bg-red-100",
        txt: "text-red-800",
      },
      {
        bg: "bg-yellow-100",
        txt: "text-yellow-800",
      },
      {
        bg: "bg-green-100",
        txt: "text-green-800",
      },
      {
        bg: "bg-blue-100",
        txt: "text-blue-800",
      },
      {
        bg: "bg-indigo-100",
        txt: "text-indigo-800",
      },
      {
        bg: "bg-pink-100",
        txt: "text-pink-800",
      },
      {
        bg: "bg-teal-100",
        txt: "text-teal-800",
      },
      {
        bg: "bg-cyan-100",
        txt: "text-cyan-800",
      },
      {
        bg: "bg-sky-100",
        txt: "text-sky-800",
      },
      {
        bg: "bg-violet-100",
        txt: "text-violet-800",
      },
      {
        bg: "bg-purple-100",
        txt: "text-purple-800",
      },
      {
        bg: "bg-fuchsia-100",
        txt: "text-fuchsia-800",
      },
      {
        bg: "bg-amber-100",
        txt: "text-amber-800",
      },
      {
        bg: "bg-orange-100",
        txt: "text-orange-800",
      },
      {
        bg: "bg-lime-100",
        txt: "text-lime-800",
      },
    ];
    var randomColor = Math.floor(Math.random() * color.length);
    var returnColor = `${color[randomColor].bg} ${color[randomColor].txt}`;
    return returnColor;
  };
  return (
    <Tippy
      className="bg-gray-50 shadow-md p-2 px-3 text-gray-800 font-semibold rounded-lg text-sm"
      content={companyName.trim()}
    >
      <div>
        {imageAvailable ? (
          <img
            className="rounded-full object-contain w-[18px] h-[18px]"
            src={
              clearBit
                ? "https://logo.clearbit.com/" +
                  companyName.trim().toLocaleLowerCase() +
                  ".com"
                : clearSplitBit
                ? `https://logo.clearbit.com/${companyName
                    .split(" " || "-")[0]
                    .trim()
                    .toLocaleLowerCase()}.com`
                : clearSplitBitTwo
                ? `https://logo.clearbit.com/${companyName
                    .split("-")[0]
                    .trim()
                    .toLocaleLowerCase()}.com`
                : companyLogoUrl
            }
            width="18"
            height="18"
            alt={"company_logo"}
            onError={() => {
              setImageAvailable(false);
              setClearBit(false);
              setClearSplitBit(false);
            }}
          />
        ) : (
          <div
            className={`flex justify-center items-center rounded-full w-[18px] h-[18px] ${color}`}
          >
            <p className="text-[14px] font-medium">
              {companyName.trim().substring(0, 1)}
            </p>
          </div>
        )}
      </div>
    </Tippy>
  );
};

export default MentorWorkedAtCard;

import { trackAnalytics } from 'api/analytics';
import { sendAnalyticsToSegment } from 'api/analyticsV2';
import { newEventPropertyBuilder, updateFirestore } from 'api/planning';
import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'state/hooks';
import Plan1 from './Plan1';
import Plan2 from './Plan2';

type Props = {}

const BothPlans = (props: Props) => {
  const [selectedPlan, setselectedPlan] = useState(1);
  const IppSession = useAppSelector(state => state.plannerInputAreas.IppPackage)
  const initialUpgradeLaunchTime = useAppSelector(state => state.generalConfigs.trial_upgrade_opened_time)
  const menteeEmail = useAppSelector(state => state.generalConfigs.menteeEmail)
  const mentorEmail = useAppSelector(state => state.generalConfigs.mentorEmail)
  const selectedPackages = useAppSelector(state => state.plannerInputAreas.selectedPackages);
  const menteeData = useAppSelector((state: any) => state.planner.menteeData)
  const [totalPakages, settotalPakages] = useState(0)
  const [totalPrice, settotalPrice] = useState(0)
  const [totalDuration, settotalDuration] = useState("")
  useEffect(() => {
    let price = 0;
    let sessions = 0;
    let duration = 0;

    selectedPackages.forEach((session: any) => {
      price = price + (session.price * session.noOfRecommendation);
      sessions = sessions + session.noOfRecommendation;
      duration = duration + (session.callDuration.split('min')[0] * session.noOfRecommendation);
    })

    settotalPakages(sessions);
    settotalPrice(price);

    if (duration > 60) {
      duration = duration / 60;
      settotalDuration(duration + " hrs")
    } else {
      settotalDuration(duration + " mins")
    }
  }, [selectedPackages])
  return (
    <div>
      <div className='flex'>
        <button className={`flex items-center justify-between gap-6 rounded-t-2xl w-1/2 text-left py-2 px-6 ${selectedPlan === 1 ? "bg-[#101828] text-[#e4e7ec]" : "bg-[#e4e7ec] text-[#101828]"}`}
          onClick={() => {
            setselectedPlan(1);
            const planChangedEventBuilder = newEventPropertyBuilder({
              mentee_email:menteeEmail,
              mentor_email:mentorEmail,
              user_email:menteeData?.email,
              product_name: "Upgrade Report",
              event_name: "Plan Changed",
              track_details:{
                plan_name: "Plan 1",
              },
              extra_track_properties: {
                time_passed: Math.round(
                  (new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000)
                ),
              },
            });
            trackAnalytics(planChangedEventBuilder)
            // sendAnalyticsToSegment.track("Plan Changed", {
            //   plan_name: "Plan 1",
            //   time_passed: Math.round((new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000))
            // })
          }}>
          <div>
            <p className='md:text-sm'>Plan 1</p>
            <p className='font-semibold hidden md:block'>Interview Preparation Programme (IPP)</p>
          </div>
          <div className='text-right'>
            <p className='md:text-sm hidden md:block whitespace-nowrap'>({IppSession.timeRequired} {IppSession.timeRequired>1? "Months" : "Month"})</p>
            <p className='font-semibold hidden md:block whitespace-nowrap'>Rs {IppSession.price}<span className='text-xs'>{IppSession.timeRequired > 1 && "/month"}</span></p>
          </div>
        </button>
        <button className={`flex items-center justify-between gap-2 rounded-t-2xl w-1/2 text-left py-2 px-6 ${selectedPlan === 2 ? "bg-[#101828] text-[#e4e7ec]" : "bg-[#e4e7ec] text-[#101828]"}`}
          onClick={() => {
            setselectedPlan(2);
            const planChangedEventBuilder = newEventPropertyBuilder({
              mentee_email:menteeEmail,
              mentor_email:mentorEmail,
              user_email:menteeData?.email,
              product_name: "Upgrade Report",
              event_name: "Plan Changed",
              track_details:{
                plan_name: "Plan 2",
              },
              extra_track_properties: {
                time_passed: Math.round(
                  (new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000)
                ),
              },
            });
            trackAnalytics(planChangedEventBuilder)
            // sendAnalyticsToSegment.track("Plan Changed", {
            //   plan_name: "Plan 2",
            //   time_passed: Math.round((new Date().getTime() - initialUpgradeLaunchTime) / (60 * 1000))
            // })
          }}>
          <div>
            <p className='md:text-sm'>Plan 2</p>
            <p className='font-semibold hidden md:block'>1:1 sessions</p>
          </div>
          <div className='text-right'>
            <p className='md:text-sm hidden md:block whitespace-nowrap'>({totalPakages} {totalPakages>1 ? "Sessions" : "Session"})</p>
            <p className='font-semibold hidden md:block whitespace-nowrap'>Rs {totalPrice}</p>
          </div>
        </button>
      </div>
      {selectedPlan === 1 && <Plan1 />}
      {selectedPlan === 2 && <Plan2 />}
    </div>
  )
}

export default BothPlans
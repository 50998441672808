import {
  getPlanningDataForMentee,
  newEventPropertyBuilder,
  postMenteeData,
  updateFirestore,
  uploadMedia,
} from "api/planning";
import { trackAnalytics } from "api/analytics";
import { setMenteeData } from "state/planner/plannerReducer";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";

const useUploadResumeHook = (setValue?: any) => {
  const [fileLoading, setFileLoading] = useState(false);
  const [resumeOpened, setResumeOpened] = useState(false);
  const [fileName, setFileName] = useState("");
  const [resume, setResume] = useState<any>("");
  const [fileUploaded, setFileUploaded] = useState(false);
  const dispatch = useAppDispatch();
  const menteeData = useAppSelector((state) => state.planner.menteeData);
  const resumeUrl = useAppSelector((state) => state.plannerInputAreas.resumeUrl);
  const [errorResponse, setErrorResponse] = useState("");
  const menteeDetails = useAppSelector((state) => state.planner.menteeData);
  const menteeEmail = useAppSelector(
    (state: any) => state.generalConfigs.menteeEmail
  );
  const mentorEmail = useAppSelector(
    (state: any) => state.generalConfigs.mentorEmail
  );
  const initialTrialLaunchTime = useAppSelector(
    (state) => state.generalConfigs.trial_doc_opened_time
  );

  const handleResumeInput = (e: any) => {
    setFileName(e.target.files[0].name);
    if (e.target.files && e.target.files[0].type !== "application/pdf") {
      if (e.target.files[0].size >= 10000000) {
        setErrorResponse("file size too large");
      } else {
        setErrorResponse("invalid file type");
      }
      e.target.value = "";
    } else if (e.target.files) {
      setErrorResponse("");
      setResume(e.target.files[0]);
    }
  };

  const handleResumeSubmit = async () => {
    if (!errorResponse) {
      setFileLoading(true);
      var formData = new FormData();
      let resumeName: any =
        menteeDetails?.candidateName + Math.random().toString();
      formData.append("file", resume, resumeName);
      uploadMedia("upload/file", formData, "resume/candidate-profile/")
        .then((resumeUploadResponse) => {
          let uploadResponseUrl =
            resumeUploadResponse.url +
            "?alt=media&token=" +
            resumeUploadResponse.token;
          const data = {
            recordId: menteeDetails.id,
            resumeUrl: uploadResponseUrl,
          };
          updateFirestore("inputAreas.resumeUrl", uploadResponseUrl);
          postMenteeData(data, { email: menteeDetails.email }).then(() => {
            getPlanningDataForMentee({ id: menteeDetails.id }).then(
              (resMenteeData) => {
                dispatch(setMenteeData(resMenteeData));
              }
            );
          });
          setFileLoading(false);
          setFileUploaded(true);
          setFileName("");
        })
        .catch((err) => {
          console.error(err);
          setFileLoading(false);
          setFileUploaded(false);
          setFileName("");
        });

      const resumeClickedEvent = newEventPropertyBuilder({
        mentee_email: menteeEmail,
        mentor_email: mentorEmail,
        user_email: menteeDetails.email,
        product_name: "Trial Doc",
        event_name: "Resume Submitted",
        extra_track_properties: {
          time_passed: Math.round(
            (new Date().getTime() - initialTrialLaunchTime) / (60 * 1000)
          ),
        },
      });
      trackAnalytics(resumeClickedEvent);
    }
  };
  const mentorData = useAppSelector((state) => state.planner.mentorData);

  const handleResumeOpen = () => {
    setResumeOpened(true);
    // const resumeButtonEvent = newEventPropertyBuilder({
    //   mentee_email: menteeEmail,
    //   mentor_email: mentorEmail,
    //   user_email: mentorData.email,
    //   product_name: "Trial Doc",
    //   event_name: "Resume Viewed",
    //   extra_track_properties: {
    //     time_passed: Math.round(
    //       (new Date().getTime() - initialTrialLaunchTime) / (60 * 1000)
    //     ),
    //   },
    // });
    // trackAnalytics(resumeButtonEvent);
  };

  const handleResumeClose = () => {
    setResumeOpened(false);
    // const resumeButtonEvent = newEventPropertyBuilder({
    //   mentee_email: menteeEmail,
    //   mentor_email: mentorEmail,
    //   user_email: mentorData.email,
    //   product_name: "Trial Doc",
    //   event_name: "Resume Closed",
    //   extra_track_properties: {
    //     time_passed: Math.round(
    //       (new Date().getTime() - initialTrialLaunchTime) / (60 * 1000)
    //     ),
    //   },
    // });
    // trackAnalytics(resumeButtonEvent);
  };
  return {
    resumeUrl,
    fileLoading,
    fileUploaded,
    fileName,
    errorResponse,
    handleResumeInput,
    handleResumeSubmit,
    resumeOpened,
    setResumeOpened,
    menteeData,
    handleResumeClose,
    handleResumeOpen
  };
};

export default useUploadResumeHook;

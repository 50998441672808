import React from 'react'
import RightSide from './right-side/RightSide'
import LeftSide from './left-side/LeftSide'

const CommonView = () => {
  return (
    <div className='flex '>
      <div className='w-1/2 h-[100vh]'>
        <LeftSide />
      </div>
      <div className='w-1/2 h-[100vh]'>
        <RightSide />
      </div>
    </div>
  )
}

export default CommonView